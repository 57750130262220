import React, { useEffect } from 'react'

import axios from 'axios'

import { useEvent } from 'shared/hooks/useDiscount'
import { baseUrl, headers } from 'http/index'

export type TEvents = {
  events: [{ id: string; title: string; eventDateStart: Date; eventDateEnd: Date }]
}

export type UseFetchEventReturn = ReturnType<typeof useFetchEvent>

export const useFetchEvent = () => {
  const [modalVisible, setModalVisible] = React.useState<boolean>(true)
  const [eventRequestError, setEventRequestError] = React.useState<string | undefined>(undefined)
  const [isEventExist, setIsEventExist] = React.useState<boolean>(false)

  const { setEventId } = useEvent()

  const currentDate: string = new Date().toISOString().split('T')[0]

  useEffect(() => {
    axios
      .get<TEvents>(baseUrl + `/api/main/v1/events/active?today=${currentDate}`, headers)
      .then((response) => {
        if (response.data.events.length) {
          const newEventId: number = +response.data.events[0].id
          const oldEventId: number = +(localStorage.getItem('eventId') ?? -1)

          if (oldEventId !== newEventId) {
            localStorage.clear()
            localStorage.setItem('eventId', `${newEventId}`)
            setEventId(`${newEventId}`)
            setIsEventExist(!!newEventId)
          } else {
            localStorage.setItem('eventId', `${newEventId}`)
            setEventId(`${newEventId}`)
            setIsEventExist(!!newEventId)
          }
        } else {
          setIsEventExist(false)
        }
        setModalVisible(false)
      })
      .catch((err: Error) => {
        setEventRequestError(err.message)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { eventRequestError, modalVisible, isEventExist }
}
