import { Link } from 'react-router-dom'
import { styled } from 'ui/styles'

export const SpanLink = styled(Link)`
  color: ${({ theme }) => theme.palette.link};
  transition: color ${({ theme }) => theme.transition.hover}ms linear;

  * {
    transition: stroke ${({ theme }) => theme.transition.hover}ms linear;
    stroke: ${({ theme }) => theme.palette.link};
  }

  :hover {
    color: ${({ theme }) => theme.palette.link};
    text-decoration: underline;

    * {
      stroke: ${({ theme }) => theme.palette.link};
    }
  }
`
