import React from 'react'

import { Page } from 'app/templates/page'
import { styled } from 'ui/styles'
import { Text, Separator } from 'ui/components'

const ContentWrapper = styled.div`
  display: flex;
  padding: 20px 20px 36px;
  flex-direction: column;
  max-width: 1000px;
  gap: 32px;
`

const SeparatorWrapper = styled.div`
  padding: 24px 0;
`

const GeneralProvisions = styled.section`
  display: flex;
  line-height: 2rem;
  flex-direction: column;
`
export const StorageRules: React.FC = () => {
  return (
    <Page>
      <ContentWrapper>
        <Text variant="h4">Правила хранения персональных данных</Text>
        <SeparatorWrapper>
          <Separator />
        </SeparatorWrapper>

        <GeneralProvisions>
          <h3>
            Политика
            <br /> ООО «ТАКС КОМПЛАЕНС»
            <br /> в&nbsp;отношении обработки персональных данных
          </h3>
          <p>
            <b>1. Общие положения</b>
          </p>
          <p>
            1.1. Настоящая Политика ООО «ТАКС КОМПЛАЕНС» в отношении обработки персональных данных (далее - Политика)
            разработана во исполнение требований п. 2 ч. 1 ст. 18.1 Федерального закона от 27.07.2006 N 152-ФЗ "О
            персональных данных" (далее - Закон о персональных данных) в целях обеспечения защиты прав и свобод человека
            и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной
            жизни, личную и семейную тайну.
          </p>
          <p>
            1.2. Политика действует в отношении всех персональных данных, которые обрабатывает общество с ограниченной
            ответственностью «ТАКС КОМПЛАЕНС» (далее - Оператор, ООО «ТАКС КОМПЛАЕНС»).
          </p>
          <p>
            1.3. Политика распространяется на отношения в области обработки персональных данных, возникшие у Оператора
            как до, так и после утверждения настоящей Политики.
          </p>
          <p>
            1.4. В случае изменения действующего законодательства, внесения изменений в нормативные акты федеральных
            органов исполнительной власти, а также во внутренние документы Оператора настоящая политика до ее приведения
            в соответствие с такими изменениями действует в части, не противоречащей действующим законодательным и иным
            нормативно-правовым актам, а также соответствующим внутренним документам ООО «ТАКС КОМПЛАЕНС»
          </p>
          <p>
            1.5. Настоящая политика является общедоступным внутренним документом ООО «ТАКС КОМПЛАЕНС» и подлежит
            размещению на официальном сайте Оператора в Интернете, а также на информационных стендах Оператора в местах
            обслуживания клиентов и работников.
          </p>
          <p>
            1.6. Персональные данные являются конфиденциальной, строго охраняемой информацией, и на них распространяются
            все требования, установленные внутренними документами Оператора к защите конфиденциальной информации.
            Обезличенные и общедоступные персональные данные не являются конфиденциальной информацией.
          </p>
          <p>
            1.7. Основные понятия, используемые в Политике:
            <br />
            <b>Персональные данные</b> — любая информация, относящаяся к прямо или косвенно определенному или
            определяемому физическому лицу (субъекту персональных данных) (далее ПДн);
            <br />
            <b>Оператор персональных данных (оператор)</b> — государственный орган, муниципальный орган, юридическое или
            физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку
            персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных,
            подлежащих обработке, действия (операции), совершаемые с персональными данными;
            <b>Обработка персональных данных</b> — любое действие (операция) или совокупность действий (операций) с
            персональными данными, совершаемых с использованием средств автоматизации или без их использования.
            Обработка персональных данных включает в себя в том числе:
            <br />
            {'•     сбор;'}
            <br />
            {'•   запись;'}
            <br />
            {'•   систематизацию;'}
            <br />
            {'•   накопление;'}
            <br />
            {'•   хранение;'}
            <br />
            {'•   уточнение (обновление, изменение);'}
            <br />
            {'•   извлечение;'}
            <br />
            {'•   использование;'}
            <br />
            {'•   передачу (распространение, предоставление, доступ);'}
            <br />
            {'•   обезличивание;'}
            <br />
            {'•   блокирование;'}
            <br />
            {'•   удаление;'}
            <br />
            {'•   уничтожение;'}
            <br />
            <b>Автоматизированная обработка персональных данных</b> — обработка персональных данных с помощью средств
            вычислительной техники;
            <br />
            <b>Распространение персональных данных</b> — действия, направленные на раскрытие персональных данных
            неопределенному кругу лиц;
            <br />
            <b>Предоставление персональных данных</b> — действия, направленные на раскрытие персональных данных
            определенному лицу или определенному кругу лиц;
            <br />
            <b>Блокирование персональных данных</b> — временное прекращение обработки персональных данных (за
            исключением случаев, если обработка необходима для уточнения персональных данных);
            <br />
            <b>Уничтожение персональных данных</b> — действия, в результате которых становится невозможным восстановить
            содержание персональных данных в информационной системе персональных данных и (или) в результате которых
            уничтожаются материальные носители персональных данных;
            <br />
            <b>Обезличивание персональных данных</b> — действия, в результате которых становится невозможным без
            использования дополнительной информации определить принадлежность персональных данных конкретному субъекту
            персональных данных;Информационная система персональных данных — совокупность содержащихся в базах данных
            персональных данных и обеспечивающих их обработку информационных технологий и технических средств;
            <br />
            <b>Трансграничная передача персональных данных</b> — передача персональных данных на территорию иностранного
            государства органу власти иностранного государства, иностранному физическому лицу или иностранному
            юридическому лицу.
            <br />
            <b>Клиенты</b> — физические и юридические лица (резиденты и нерезиденты), индивидуальные предприниматели,
            физические лица, заключившие с Оператором договор на оказание услуг.
            <br />
            <b>Представитель</b> — лицо, действующее от имени представляемого лица (работника, клиента, корреспондента,
            контрагента, партнера) на основании закона, договора или доверенности.
          </p>
          <p>1.8. Основные права и обязанности Оператора.</p>
          <p>
            1.8.1. Оператор имеет право:
            <br />
            1) самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения
            обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним нормативными
            правовыми актами, если иное не предусмотрено Законом о персональных данных или другими федеральными
            законами;
            <br />
            2) поручить обработку персональных данных другому лицу с согласия субъекта персональных данных, если иное не
            предусмотрено федеральным законом, на основании заключаемого с этим лицом договора. Лицо, осуществляющее
            обработку персональных данных по поручению Оператора, обязано соблюдать принципы и правила обработки
            персональных данных, предусмотренные Законом о персональных данных;
            <br />
            3) в случае отзыва субъектом персональных данных согласия на обработку персональных данных Оператор вправе
            продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований,
            указанных в Законе о персональных данных.
            <br />
            4) осуществлять проверку точности, достоверности и актуальности предоставляемых ПДн в случаях, объеме и
            порядке, предусмотренных и установленных законодательством
            <br />
            5) отказывать в предоставлении ПДн в случаях, предусмотренных законодательством;
            <br />
            6) отказать субъекту ПДн в случае его отказа от предоставления (непредставления) ООО «ТАКС КОМПЛАЕНС» своих
            ПДн, обработка которых необходима для оказания субъекту услуг, в предоставлении соответствующих услуг в
            законном порядке, за исключением случаев, предусмотренных законодательством;
            <br />
            7) отстаивать свои интересы в суде;
            <br />
            8) реализовывать иные права, предусмотренные законом или договором.
            <br />
          </p>
          <p>
            1.8.2. Оператор обязан:
            <br />
            1) не раскрывать третьим лицам и не распространять ПДн без согласия субъекта ПДн и если иное не
            предусмотрено законодательством;
            <br />
            2) использовать ПДн субъекта без его согласия в случаях, предусмотренных законодательством;
            <br />
            3) предоставлять ПДн субъектов третьим лицам, если это предусмотрено действующим законодательством
            (налоговым, правоохранительным органам и др.);
            <br />
            5) по требованию субъекта ПДн прекратить обработку его ПДн, за исключением случаев, предусмотренных
            законодательством;
            <br />
            6) принимать меры, необходимые и достаточные для обеспечения выполнения обязанностей, предусмотренных
            Законом N 152-ФЗ и иными законодательными актами РФ;
            <br />
            7) до начала обработки ПДн уведомить уполномоченный орган по защите прав субъектов ПДн о своем намерении
            осуществлять обработку ПДн, за исключением случаев, предусмотренных законодательством. Уведомление об
            обработке ПДн, содержащее сведения, предусмотренные частью 3 статьи 22 Закона N 152-ФЗ, подписывается
            уполномоченным лицом и направляется в уполномоченный орган в виде документа на бумажном носителе или в форме
            электронного документа в порядке, установленном или рекомендованном уполномоченным органом;
            <br />
            8) в случае изменения сведений, указанных в уведомлении об обработке ПДн (в том числе предусмотренных частью
            3 статьи 22 Закона N 152-ФЗ), а также в случае прекращения обработки ПДн уведомить об этом уполномоченный
            орган по защите прав субъектов ПДн в течение десяти рабочих дней с даты возникновения таких изменений или с
            даты прекращения обработки ПДн;
            <br />
            9) при получении запроса уполномоченного органа по защите прав субъектов ПДн представить уполномоченному
            органу документы и локальные акты, указанные в части 1 статьи 18.1 Закона N 152-ФЗ, и (или) иным образом
            подтвердить принятие мер, указанных в части 1 статьи 18.1 Закона N 152-ФЗ, сообщить в уполномоченный орган
            необходимую информацию в течение 30 дней с даты получения такого запроса;
            <br />
            10) выполнять иные обязанности, предусмотренные законом или договором.
          </p>
          <p>1.9. Основные права и обязанности субъекта персональных данных.</p>
          <p>
            1.9.1. Субъект персональных данных имеет право:
            <br />
            1) свободного бесплатного доступа к своим ПДн, включая право на получение копий любой записи, содержащей
            ПДн, за исключением случаев, предусмотренных законодательством;
            <br />
            2) получать информацию, касающуюся обработки его персональных данных, за исключением случаев,
            предусмотренных федеральными законами. Сведения предоставляются субъекту персональных данных Оператором в
            доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим субъектам
            персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких
            персональных данных. Перечень информации и порядок ее получения установлен Законом о персональных данных;
            <br />
            3) требовать от оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если
            персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются
            необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих
            прав;
            <br />
            4) требовать предоставления перечня своих ПДн (ПДн субъекта), обрабатываемых Оператором, и информации об
            источнике их получения, если иное не предусмотрено законодательством;
            <br />
            5) получать информацию о сроках обработки своих ПДн (ПДн субъекта), в том числе о сроках их хранения;
            <br />
            6) требовать извещения всех лиц, которым ранее были сообщены неверные или неполные (неточные, неактуальные)
            его ПДн (ПДн субъекта), обо всех произведенных в них исключениях, исправлениях или дополнениях;
            <br />
            7) выдвигать условие предварительного согласия при обработке персональных данных в целях продвижения на
            рынке товаров, работ и услуг;
            <br />
            8) обжаловать в Роскомнадзоре или в судебном порядке неправомерные действия или бездействие Оператора при
            обработке его персональных данных.
            <br />
            9) на защиту своих прав (прав субъекта ПДн) и законных интересов, в том числе на возмещение убытков и (или)
            компенсацию морального вреда в судебном порядке
            <br />
            10) определять своих представителей для защиты своих ПДн;
            <br />
            11) отозвать согласие на обработку своих ПДн (ПДн субъекта), за исключением случаев, предусмотренных
            законодательством
            <br />
            12) реализовывать иные права, предусмотренные законом или договором.
          </p>
          <p>
            1.9.2. Субъект ПДн (его представитель) обязан:
            <br />
            1) своевременно предоставлять Оператору сведения об изменении своих ПДн (ПДн субъекта), если такая
            обязанность предусмотрена договором между субъектом ПДн и Оператором, законодательством или внутренними
            документами Оператора;
            <br />
            2) при предоставлении Оператору своих ПДн (ПДн субъекта) обеспечивать на момент предоставления ПДн их
            точность, достоверность и актуальность, за которые субъект несет ответственность в соответствии с
            действующим законодательством;
            <br />
            3) выполнять иные обязанности, предусмотренные законом или договором.
          </p>
          <p>
            1.10.Контроль за исполнением требований настоящей Политики осуществляется уполномоченным лицом,
            ответственным за организацию обработки персональных данных у Оператора.
          </p>
          <p>
            1.11. Ответственность за нарушение требований законодательства Российской Федерации и нормативных актов ООО
            «ТАКС КОМПЛАЕНС» в сфере обработки и защиты персональных данных определяется в соответствии с
            законодательством Российской Федерации.
          </p>
          <SeparatorWrapper>
            <Separator />
          </SeparatorWrapper>
          <p>
            <b>2. Цели сбора персональных данных</b>
          </p>
          <p>
            2.1. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных
            целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.
          </p>
          <p>2.2. Обработке подлежат только персональные данные, которые отвечают целям их обработки.</p>
          <p>
            2.3. Обработка Оператором персональных данных осуществляется в следующих целях:
            <br />
            • обеспечение соблюдения Конституции Российской Федерации, федеральных законов и иных нормативных правовых
            актов Российской Федерации;
            <br />
            • осуществление своей деятельности в соответствии с уставом ООО «ТАКС КОМПЛАЕНС» и в соответствии с
            выданными лицензиями;
            <br />
            • заключения, исполнения и прекращения трудовых, гражданско-правовых и иных договоров и соглашений с
            физическими, юридическим лицами, индивидуальными предпринимателями и иными лицами, рассмотрения возможности
            заключения трудового договора (соглашения), договора гражданско-правового характера с субъектом ПДн (в
            порядке и в случаях, предусмотренных действующим законодательством и уставом ООО «ТАКС КОМПЛАЕНС»);
            <br />
            • организации кадрового учета Оператора, обеспечения соблюдения актов законодательства и иных
            нормативно-правовых актов, содержащих нормы трудового права, заключения и исполнения обязательств по
            трудовым и гражданско-правовым договорам;
            <br />
            • ведения кадрового делопроизводства, содействия работникам в трудоустройстве, обучении и продвижении по
            службе, пользования различного вида льготами, добровольного страхования работников, исполнения требований
            налогового законодательства в связи с исчислением и уплатой налога на доходы физических лиц, а также единого
            социального налога, пенсионного законодательства при формировании и представлении персонифицированных данных
            о каждом получателе доходов, учитываемых при начислении страховых взносов на обязательное пенсионное
            страхование и обеспечение, заполнения первичной статистической документации, в соответствии с действующим
            законодательством, а также уставом и внутренними документами ООО «ТАКС КОМПЛАЕНС»;
            <br />
            • обеспечения личной безопасности работников, контроля количества и качества выполняемой работы и
            обеспечения сохранности имущества работника и работодателя, обеспечения пропускного режима на объектах
            Оператора и безопасности физических лиц - посетителей Оператора (в случае прохода указанных лиц на
            территорию), безопасности информации, обрабатываемой на объектах и в помещениях Оператора.
            <br />
            • привлечение и отбор кандидатов на работу у Оператора;
            <br />
            • организация постановки на индивидуальный (персонифицированный) учет работников в системе обязательного
            пенсионного страхования;
            <br />
            • заполнение и передача в органы исполнительной власти и иные уполномоченные организации требуемых форм
            отчетности, в том числе в рамках проведения проверок соблюдения лицензионных требований;
            <br />
            • осуществление гражданско-правовых отношений;
            <br />
            • ведение бухгалтерского учета;
            <br />
            • осуществление пропускного режима;
            <br />
            • выполнения мероприятий по соблюдению санитарно-эпидемиологического режима;
            <br />
            • передачи Оператором ПДн или поручения их обработки третьим лицам в соответствии с действующим
            законодательством;
            <br />• направления информации (в том числе в целях продвижения на рынке), предоставления сведений
            уведомительного или маркетингового характера (в том числе путем осуществления прямых контактов с клиентом
            (потенциальным клиентом) с помощью средств связи, включая электронные средства связи, почтовые отправления,
            СМС-сообщения), включая сведения о новых услугах, совместных продуктах Оператора и третьих лиц, продуктах
            (товарах, работах, услугах) третьих лиц, проводимых акциях, мероприятиях, по которым имеется предварительное
            согласие клиента на их получение; рассмотрения возможности установления договорных отношений с субъектом ПДн
            по его инициативе с целью дальнейшего предоставления услуг путем заключения договора, одной из сторон
            которого либо выгодоприобретателем по которому является субъект ПДн;
          </p>
          <p>
            2.4. Обработка персональных данных работников может осуществляться исключительно в целях обеспечения
            соблюдения законов и иных нормативных правовых актов.
          </p>
          <SeparatorWrapper>
            <Separator />
          </SeparatorWrapper>
          <p>
            <b>3. Правовые основания обработки персональных данных</b>
          </p>
          <p>
            3.1. Правовым основанием обработки персональных данных является совокупность нормативных правовых актов, во
            исполнение которых и в соответствии с которыми Оператор осуществляет обработку персональных данных, в том
            числе:
            <br />
            • Конвенция о защите физических лиц при автоматизированной обработке персональных данных (заключена в г.
            Страсбурге 28.01.1981);
            <br />
            • Модельный закон о персональных данных (принят в г. Санкт-Петербурге 16.10.1999 Постановлением N 14-19 на
            14-м пленарном заседании Межпарламентской ассамблеи государств - участников СНГ;
            <br />
            • Конституция Российской Федерации;
            <br />
            • Гражданский кодекс Российской Федерации;
            <br />
            • Трудовой кодекс Российской Федерации;
            <br />
            • Налоговый кодекс Российской Федерации;
            <br />
            • Федеральный закон от 08.02.1998 N 14-ФЗ "Об обществах с ограниченной ответственностью";
            <br />
            • Федеральный закон от 06.12.2011 N 402-ФЗ "О бухгалтерском учете";
            <br />
            • Федеральный закон от 15.12.2001 N 167-ФЗ "Об обязательном пенсионном страховании в Российской Федерации";
            <br />• иные нормативные правовые акты, регулирующие отношения, связанные с деятельностью Оператора.
          </p>
          <p>
            3.2. Правовым основанием обработки персональных данных также являются:
            <br />
            • устав ООО «ТАКС КОМПЛАЕНС»;
            <br />
            • договоры, заключаемые между Оператором и субъектами персональных данных;
            <br />
            • согласие субъектов персональных данных на обработку их персональных данных.
            <br />
          </p>
          <SeparatorWrapper>
            <Separator />
          </SeparatorWrapper>
          <p>
            <b>4. Объем и категории обрабатываемых персональных данных, категории субъектов персональных данных</b>
          </p>
          <p>
            4.1. Содержание и объем обрабатываемых персональных данных должны соответствовать заявленным целям
            обработки, предусмотренным в разд. 2 настоящей Политики. Обрабатываемые персональные данные не должны быть
            избыточными по отношению к заявленным целям их обработки.
          </p>
          <p>4.2. Оператор может обрабатывать персональные данные следующих категорий субъектов персональных данных.</p>
          <p>
            4.2.1. Кандидаты для приема на работу к Оператору:
            <br />
            • фамилия, имя, отчество;
            <br />
            • пол;
            <br />
            • гражданство;
            <br />
            • дата и место рождения;
            <br />
            • контактные данные;
            <br />
            • сведения об образовании, опыте работы, квалификации;
            <br />
            • сведения о состоянии здоровья о показаниях и противопоказаниях к исполнению трудовых обязанностей;
            <br />• иные персональные данные, сообщаемые кандидатами в резюме и сопроводительных письмах.
          </p>
          <p>
            4.2.2. Работники и бывшие работники Оператора:
            <br />
            • фамилия, имя, отчество;
            <br />
            • пол;
            <br />
            • гражданство;
            <br />
            • дата и место рождения;
            <br />
            • изображение (фотография);
            <br />
            • паспортные данные;
            <br />
            • адрес регистрации по месту жительства;
            <br />
            • адрес фактического проживания;
            <br />
            • контактные данные;
            <br />
            • индивидуальный номер налогоплательщика;
            <br />
            • страховой номер индивидуального лицевого счета (СНИЛС);
            <br />
            • сведения об образовании, квалификации, профессиональной подготовке и повышении квалификации;
            <br />
            • семейное положение, наличие детей, родственные связи;
            <br />
            • сведения о трудовой деятельности, в том числе наличие поощрений, награждений и (или) дисциплинарных
            взысканий;
            <br />
            • данные о регистрации брака;
            <br />
            • сведения о воинском учете;
            <br />
            • сведения об инвалидности;
            <br />
            • сведения об удержании алиментов;
            <br />
            • сведения о доходе с предыдущего места работы;
            <br />
            • сведения о состоянии здоровья о показаниях и противопоказаниях к исполнению трудовых обязанностей;
            <br />• иные персональные данные, предоставляемые работниками в соответствии с требованиями трудового
            законодательства.
          </p>
          <p>
            4.2.3. Члены семьи работников Оператора:
            <br />
            • фамилия, имя, отчество;
            <br />
            • степень родства;
            <br />
            • год рождения;
            <br />• иные персональные данные, предоставляемые работниками в соответствии с требованиями трудового
            законодательства.
          </p>
          <p>
            4.2.4. Клиенты и контрагенты Оператора (физические лица):
            <br />
            • фамилия, имя, отчество;
            <br />
            • дата и место рождения;
            <br />
            • паспортные данные;
            <br />
            • адрес регистрации по месту жительства;
            <br />
            • контактные данные;
            <br />
            • замещаемая должность;
            <br />
            • индивидуальный номер налогоплательщика;
            <br />
            • номер расчетного счета;
            <br />• иные персональные данные, предоставляемые клиентами и контрагентами (физическими лицами),
            необходимые для заключения и исполнения договоров.
          </p>
          <p>
            4.2.5. Представители (работники) клиентов и контрагентов Оператора (юридических лиц):
            <br />
            • фамилия, имя, отчество;
            <br />
            • паспортные данные;
            <br />
            • контактные данные;
            <br />
            • замещаемая должность;
            <br />• иные персональные данные, предоставляемые представителями (работниками) клиентов и контрагентов,
            необходимые для заключения и исполнения договоров.
          </p>
          <p>
            4.3.Обработка Оператором биометрических персональных данных (сведений, которые характеризуют физиологические
            и биологические особенности человека, на основании которых можно установить его личность) осуществляется в
            соответствии с законодательством Российской Федерации.
          </p>
          <p>
            4.4. Оператором не осуществляется обработка специальных категорий персональных данных, касающихся расовой,
            национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния
            здоровья, интимной жизни, за исключением случаев, предусмотренных законодательством РФ.
          </p>
          <SeparatorWrapper>
            <Separator />
          </SeparatorWrapper>
          <p>
            <b>5. Порядок и условия обработки персональных данных</b>
          </p>
          <p>
            5.1. Обработка персональных данных осуществляется Оператором в соответствии с требованиями законодательства
            Российской Федерации.
          </p>
          <p>
            5.2. Обработка персональных данных осуществляется с согласия субъектов персональных данных на обработку их
            персональных данных, а также без такового в случаях, предусмотренных законодательством Российской Федерации.
          </p>
          <p>
            5.3. Оператор осуществляет как автоматизированную, так и неавтоматизированную обработку персональных данных.
          </p>
          <p>
            5.4. К обработке персональных данных допускаются работники Оператора, в должностные обязанности которых
            входит обработка персональных данных.
          </p>
          <p>
            5.5. Обработка персональных данных осуществляется путем:
            <br />
            • получения персональных данных в устной и письменной форме непосредственно от субъектов персональных
            данных;
            <br />
            • получения персональных данных из общедоступных источников;
            <br />
            • получения персональных данных от органов исполнительной власти и иных уполномоченных органов,
            контролирующих деятельность Оператора;
            <br />
            • внесения персональных данных в журналы, реестры и информационные системы Оператора;
            <br />• использования иных способов обработки персональных данных.
          </p>
          <p>
            5.6. Не допускается раскрытие третьим лицам и распространение персональных данных без согласия субъекта
            персональных данных, если иное не предусмотрено федеральным законом.
          </p>
          <p>
            5.7. Передача персональных данных органам дознания и следствия, в Федеральную налоговую службу, Пенсионный
            фонд Российской Федерации, Фонд социального страхования, и другие уполномоченные органы исполнительной
            власти и организации осуществляется в соответствии с требованиями законодательства Российской Федерации.
          </p>
          <p>
            5.8. Оператор осуществляет хранение персональных данных в форме, позволяющей определить субъекта
            персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения
            персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем
            или поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные
            подлежат уничтожению либо обезличиванию по достижении целей обработки или в случае утраты необходимости в
            достижении этих целей, если иное не предусмотрено федеральным законом.
          </p>
          <p>
            5.9. При сборе персональных данных, в том числе посредством информационно-телекоммуникационной сети
            Интернет, Оператор обеспечивает запись, систематизацию, накопление, хранение, уточнение (обновление,
            изменение), извлечение персональных данных граждан Российской Федерации с использованием баз данных,
            находящихся на территории Российской Федерации, за исключением случаев, указанных в Законе о персональных
            данных.
          </p>
          <SeparatorWrapper>
            <Separator />
          </SeparatorWrapper>
          <p>
            <b>6. Обеспечение безопасности персональных данных</b>
          </p>
          <p>
            6.1. Организация и проведение мероприятий по обеспечению безопасности и защиты ПДн в организации
            осуществляются в соответствии с настоящей политикой и иными внутренними документами ООО «ТАКС КОМПЛАЕНС»
          </p>
          <p>
            6.2. В целях обеспечения безопасности и защиты ПДн при их обработке Оператор принимает необходимые и
            достаточные правовые, организационные и технические меры (или обеспечивает их принятие) для защиты ПДн от
            неправомерного (несанкционированного) или случайного доступа к ним, уничтожения, изменения, блокирования,
            копирования, предоставления, распространения ПДн, а также от иных неправомерных действий в отношении ПДн.
            Указанные меры осуществляются Оператором в строгом соответствии с требованиями законодательства,
            нормативно-правовых и иных актов в области обработки и защиты ПДн.
          </p>
          <p>
            6.3.В состав и перечень необходимых и достаточных для обеспечения выполнения требований законодательства
            мер, принимаемых Оператором и направленных на обеспечение безопасности и защиты ПДн, которые в соответствии
            со статьей 18.1 Закона N 152-ФЗ определяются Оператором самостоятельно, входят:
          </p>
          <p>
            6.3.1. Правовые меры:
            <br />
            • обязательство работника Оператора, закрепленное в трудовом договоре, заключенном между Оператором и
            работником, о неразглашении конфиденциальной информации;
            <br />
            • обязанность работников Оператора, закрепленная во внутренних документах Оператора, выполнять требования по
            соблюдению конфиденциальности и защиты ПДн работников и клиентов Оператора, ставших известными работнику в
            рамках исполнения им своих должностных обязанностей;
            <br />
            • обязательное включение в заключаемые Оператором с взаимодействующими организациями и физическими лицами
            соглашения о передаче ПДн требований соблюдения конфиденциальности (включая обязательство неразглашения) и
            обеспечения безопасности ПДн при их обработке;
            <br />• документальное оформление требований к безопасности обрабатываемых данных.
          </p>
          <p>
            6.3.2. Организационные меры:
            <br />
            • назначение должностного лица, ответственного за организацию работ по защите ПДн в ООО «ТАКС КОМПЛАЕНС»;
            <br />
            • разработка и внедрение внутренних документов по вопросам обработки и защиты ПДн, а также внутренних
            документов, устанавливающих процедуры, направленные на предотвращение и выявление нарушений установленных
            процедур по обработке ПДн и устранение последствий таких нарушений;
            <br />
            • установление персональной ответственности работников Оператора, за обеспечение безопасности обрабатываемых
            ПДн;
            <br />
            • ознакомление работников Оператора, непосредственно осуществляющих обработку ПДн, с требованиями и
            содержанием актов законодательства, нормативно-правовых и иных актов регулирующих деятельность, федеральных
            органов исполнительной власти, настоящей политикой и иными внутренними документами Оператора в области
            обработки ПДн, обеспечения их безопасности и защиты;
            <br />
            • мониторинг изменений законодательства, нормативно-правовых и иных актов в сфере обработки и защиты ПДн, в
            том числе рекомендаций уполномоченного органа по защите прав субъектов ПДн, контролирующего деятельность
            Оператора по обеспечению безопасности ПДн, ознакомление со значимыми изменениями и указанными рекомендациями
            всех работников Оператора, непосредственно осуществляющих обработку ПДн, и приведение в соответствие с ними
            внутренних документов Оператора (в том числе регламентов, инструкций и т.д.);
            <br />
            • контроль выполнения подразделениями, должностными лицами и работниками Оператора требований
            законодательства, нормативно-правовых актов, настоящей политики и иных внутренних документов Оператора в
            области обработки и защиты ПДн, контроль соответствия обработки и защиты ПДн в ООО «ТАКС КОМПЛАЕНС»
            указанным требованиям;
            <br />
            • реализация принципа достаточности обрабатываемых ПДн (при определении состава обрабатываемых ПДн субъектов
            ПДн Оператор руководствуется минимально необходимым составом ПДн для достижения целей получения и обработки
            ПДн);
            <br />
            • своевременное выявление угроз безопасности ПДн (в том числе при их обработке в ИСПДн), разработка и
            утверждение моделей угроз безопасности ПДн в соответствии с требованиями законодательства;
            <br />
            • учет машинных носителей ПДн;
            <br />
            • хранение материальных носителей ПДн в закрытых шкафах, ящиках, сейфах;
            <br />
            • организация контроля доступа в помещения и здания Оператора, их охрана в рабочее и нерабочее время,
            ограничение доступа в помещения, где хранятся ПДн;
            <br />
            • организация и реализация системы ограничения (разграничения) доступа пользователей (обслуживающего
            персонала) к документам, информационным ресурсам и машинным носителям информации, информационным системам и
            связанным с их использованием работам;
            <br />
            • систематический анализ (мониторинг) безопасности ПДн, регулярные проверки и совершенствование системы их
            защиты;
            <br />• контроль и оценка эффективности принимаемых мер по обеспечению безопасности ПДн и уровня
            защищенности ИСПДн.
          </p>
          <SeparatorWrapper>
            <Separator />
          </SeparatorWrapper>
          <p>
            <b>
              7. Актуализация, исправление, удаление и уничтожение персональных данных, ответы на запросы субъектов на
              доступ к персональным данным
            </b>
          </p>
          <p>
            7.1. Подтверждение факта обработки персональных данных Оператором, правовые основания и цели обработки
            персональных данных, а также иные сведения, указанные в ч. 7 ст. 14 Закона о персональных данных,
            предоставляются Оператором субъекту персональных данных или его представителю при обращении либо при
            получении запроса субъекта персональных данных или его представителя.
          </p>
          <p>
            В предоставляемые сведения не включаются персональные данные, относящиеся к другим субъектам персональных
            данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных.
          </p>
          <p>
            Запрос должен содержать:
            <br />
            • номер основного документа, удостоверяющего личность субъекта персональных данных или его представителя,
            сведения о дате выдачи указанного документа и выдавшем его органе;
            <br />
            • сведения, подтверждающие участие субъекта персональных данных в отношениях с Оператором (номер договора,
            дата заключения договора, условное словесное обозначение и (или) иные сведения), либо сведения, иным образом
            подтверждающие факт обработки персональных данных Оператором;
            <br />• подпись субъекта персональных данных или его представителя.
          </p>
          <p>
            Запрос может быть направлен в форме электронного документа и подписан электронной подписью в соответствии с
            законодательством Российской Федерации.
          </p>
          <p>
            Если в обращении (запросе) субъекта персональных данных не отражены в соответствии с требованиями Закона о
            персональных данных все необходимые сведения или субъект не обладает правами доступа к запрашиваемой
            информации, то ему направляется мотивированный отказ.
          </p>
          <p>
            Право субъекта персональных данных на доступ к его персональным данным может быть ограничено в соответствии
            с ч. 8 ст. 14 Закона о персональных данных, в том числе, если доступ субъекта персональных данных к его
            персональным данным нарушает права и законные интересы третьих лиц.
          </p>
          <p>
            7.2. В случае выявления неточных персональных данных при обращении субъекта персональных данных или его
            представителя либо по их запросу или по запросу Роскомнадзора Оператор осуществляет блокирование
            персональных данных, относящихся к этому субъекту персональных данных, с момента такого обращения или
            получения указанного запроса на период проверки, если блокирование персональных данных не нарушает права и
            законные интересы субъекта персональных данных или третьих лиц.
          </p>
          <p>
            В случае подтверждения факта неточности персональных данных Оператор на основании сведений, представленных
            субъектом персональных данных или его представителем либо Роскомнадзором, или иных необходимых документов
            уточняет персональные данные в течение семи рабочих дней со дня представления таких сведений и снимает
            блокирование персональных данных.
          </p>
          <p>
            7.3. В случае выявления неправомерной обработки персональных данных при обращении (запросе) субъекта
            персональных данных или его представителя либо Роскомнадзора Оператор осуществляет блокирование неправомерно
            обрабатываемых персональных данных, относящихся к этому субъекту персональных данных, с момента такого
            обращения или получения запроса.
          </p>
          <p>
            7.4. При достижении целей обработки персональных данных, а также в случае отзыва субъектом персональных
            данных согласия на их обработку персональные данные подлежат уничтожению, если:
            <br />
            • иное не предусмотрено договором, стороной которого, выгодоприобретателем или поручителем по которому
            является субъект персональных данных;
            <br />
            • оператор не вправе осуществлять обработку без согласия субъекта персональных данных на основаниях,
            предусмотренных Законом о персональных данных или иными федеральными законами;
            <br />• иное не предусмотрено другим соглашением между Оператором и субъектом персональных данных.
          </p>
          <SeparatorWrapper>
            <Separator />
          </SeparatorWrapper>
          <p>
            <b>8. Заключительные положения</b>
          </p>
          <p>
            8.1. Общее руководство организацией работ по защите ПДн в ООО «ТАКС КОМПЛАЕНС» осуществляет назначаемый
            внутренним распорядительным документом (приказом генерального директора) сотрудник, ответственный за
            обеспечение безопасности ПДн (далее - ответственный сотрудник за безопасность ПДн).
          </p>
          <p>
            8.2. При необходимости для выбора и обоснования мер защиты ПДн, их уточнения и контроля за их исполнением,
            на основании внутреннего распорядительного документа (приказа генерального директора) может дополнительно
            создаваться (функционировать на постоянной или временной основе) комиссия по обеспечению выполнения
            законодательных требований при обработке ПДн, в состав которой включается сотрудник, ответственный за
            безопасность ПДн.
          </p>
          <p>
            8.3. Каждый новый работник Оператора, непосредственно осуществляющий обработку ПДн, подлежит ознакомлению с
            требованиями законодательства по обработке и обеспечению безопасности ПДн, с настоящей политикой и другими
            внутренними документами Оператора по вопросам обработки ПДн и обязуется их соблюдать.
          </p>
          <p>
            8.4. Ответственность должностных лиц Оператора, имеющих доступ к ПДн, за невыполнение требований норм,
            регулирующих обработку и защиту ПДн, определяется в соответствии с действующим законодательством и
            внутренними документами Оператора.
          </p>
        </GeneralProvisions>
      </ContentWrapper>
    </Page>
  )
}
