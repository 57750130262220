import React from 'react'

import { TConsultant, TConsultants } from 'shared/hooks/useApplicationForm'
import { getStyles, styled, theme } from '../styles'
import { Text } from './text'
import { ReactComponent as Arrow } from 'ui/icons/arrow.svg'

type StyledSelectProps = { isOpened: boolean; disabled?: boolean }

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 6px;
`

const StyledText = styled(Text)`
  color: ${theme.palette.deep_blue};
  transition-property: color;
  transition-duration: ${theme.transition.hover}ms;
`

const StyledFirstName = styled(Text)`
  color: ${theme.palette.deep_blue};
  text-overflow: ellipsis;
  overflow: hidden;
  width: 85%;
  white-space: nowrap;

  transition-property: color;
  transition-duration: ${theme.transition.hover}ms;
`

const StyledSecondName = styled(Text)`
  color: ${theme.palette.deep_blue};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  transition-property: color;
  transition-duration: ${theme.transition.hover}ms;
`

const StyledSelect = styled.div<StyledSelectProps>`
  ${getStyles('t3')}

  cursor: pointer;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-self: start;
  position: relative;

  padding: 2px 16px;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: 1px solid
    ${({ disabled, isOpened }) =>
      disabled ? theme.palette.light_gray : isOpened ? theme.palette.link : theme.palette.dark_gray};
  background-color: ${({ disabled }) => (disabled ? theme.palette.blue_pastel : theme.palette.white)};

  transition-property: all;
  transition-duration: ${theme.transition.hover}ms;

  &:focus {
    border: 1px solid ${theme.palette.link};
  }
`

const StyledArrow = styled(Arrow)<{ isOpened: boolean; disabled: boolean }>`
  position: absolute;
  top: 16px;
  right: 16px;

  transition-property: transform;
  transition-duration: ${theme.transition.hover}ms;
  transform: rotate(${({ isOpened }) => (isOpened ? 180 : 0)}deg);
`

const OptionList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0px;

  width: 100%;
  background-color: ${theme.palette.white};
  border: 1px solid ${theme.palette.link};
  box-sizing: border-box;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`

const Option = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  transition-property: background-color;
  transition-duration: ${theme.transition.hover}ms;

  &:hover {
    background-color: ${theme.palette.light_blue};
    color: ${theme.palette.white};
  }

  &:focus {
    background-color: ${theme.palette.link};
  }
`

type SelectProps = {
  label: string
  options: TConsultants | undefined
  onDropDownChange(option: TConsultant): void
  isDisabled?: boolean
  selectedValue?: TConsultant
  checkIfDisabled?(option: TConsultants): boolean
}

export const Select: React.FC<SelectProps> = ({
  label,
  options,
  onDropDownChange,
  isDisabled = false,
  selectedValue
}) => {
  const [opened, setOpened] = React.useState<boolean>(false)

  const selectOption = (value: TConsultant) => {
    setOpened(false)
    onDropDownChange(value)
  }

  const handleSelect = () => {
    if (!isDisabled) {
      setOpened(!opened)
    }
  }

  return (
    <Wrapper>
      <StyledText variant="t4" color={theme.palette.deep_blue}>
        {label}
      </StyledText>
      <StyledSelect onClick={() => handleSelect()} isOpened={opened} disabled={isDisabled}>
        {!isDisabled && (
          <>
            <StyledFirstName variant="t4">{`${
              (selectedValue?.firstName ?? '') + ' ' + (selectedValue?.lastName ?? '')
            }`}</StyledFirstName>
            <StyledSecondName variant="t7" color={theme.palette.gray}>
              {selectedValue?.position}
            </StyledSecondName>
          </>
        )}
        <StyledArrow isOpened={opened && !isDisabled} disabled={isDisabled} />
      </StyledSelect>
      {opened && !isDisabled && (
        <OptionList>
          {options?.consultants ? (
            options?.consultants.map((el) => (
              <Option key={el.id} onClick={() => selectOption(el)}>
                <Text variant="t4">{`${el.firstName} ${el.lastName}`}</Text>
                <Text variant="t7" color={theme.palette.light_gray}>
                  {el.position}
                </Text>
              </Option>
            ))
          ) : (
            <StyledText variant="t4" color={theme.palette.deep_blue} align="center">
              Пусто
            </StyledText>
          )}
        </OptionList>
      )}
    </Wrapper>
  )
}
