import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { App } from 'app'
import { GlobalStyles, theme, ThemeProvider } from './ui/styles'
import { EventProvider } from 'shared/hooks/useDiscount'

const Root: React.FC = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <BrowserRouter>
      <EventProvider>
        <App />
      </EventProvider>
    </BrowserRouter>
  </ThemeProvider>
)

ReactDOM.render(<Root />, document.getElementById('root'))
