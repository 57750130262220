import React from 'react'

import { SizedBox, Text, BlueButton } from 'ui/components'
import { styled, theme } from 'ui/styles'

import { ReactComponent as Postfix } from 'ui/icons/postfix.svg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;

  border-radius: 16px;
  background-color: ${theme.palette.deep_blue};

  width: 100%;
  height: fit-content;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
`

const StyledText = styled(Text)`
  width: 100%;
`

const PostfixLogo = styled(Postfix)`
  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 8px;
`

type DiscountCardProps = {
  handleClick: () => void
}

export const DiscountCard: React.FC<DiscountCardProps> = ({ handleClick }) => {
  return (
    <Wrapper>
      <Row>
        <Text variant="t_22_22" color={theme.palette.white}>
          Получите скидку
        </Text>
        <PostfixLogo />
      </Row>
      <StyledText variant="t3" color={theme.palette.white}>
        Условия для получения скидки и правила игры...
      </StyledText>
      <SizedBox height={24} />
      <BlueButton onClick={handleClick} big>
        Сыграть в игру
      </BlueButton>
    </Wrapper>
  )
}
