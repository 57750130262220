import React from 'react'

import { ConfirmationCircle, OutlinedButton, Text } from 'ui/components'
import { styled, theme } from 'ui/styles'
import { DiscountCard } from '../molecules'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 32px 16px;
  gap: 26px;

  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
  border-radius: 16px;

  animation: onShow ${theme.transition.slide}ms ease;

  @keyframes onShow {
    0% {
      opacity: 0;
      transform: translateY(-30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const CircleWrapper = styled.div`
  display: flex;
  width: 180px;
  height: 180px;
  justify-content: center;
  align-items: center;
`
const TextSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 8px;
`

const ApplicationNumber = styled.div`
  display: flex;
  max-width: 300px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`

type ApplicationStatusProps = {
  returnToForm: () => void
  handlerGetDiscount: () => void
}

export const ApplicationStatus: React.FC<ApplicationStatusProps> = ({ handlerGetDiscount, returnToForm }) => {
  const applicationNumber: string = localStorage.getItem('applicationNumber') ?? ''
  const gameCount: number = +(localStorage.getItem('gameCount') ?? 0)
  const consultantId: boolean = !!localStorage.getItem('consultantId')
  const discount: boolean = !!localStorage.getItem('discount')
  const discountActivated: boolean = !!localStorage.getItem('discountActivated')

  return (
    <Wrapper>
      <CircleWrapper>
        <ConfirmationCircle isSuccessfully={true} />
      </CircleWrapper>

      <TextSection>
        <Text variant="h6" align="center">
          {consultantId ? 'ОТЛИЧНЫЙ ВЫБОР' : discount ? 'СКИДКА АКТИВИРОВАНА' : 'СПАСИБО ЗА ИНТЕРЕС'}
        </Text>
        <Text variant="t5" align="center" color={theme.palette.gray}>
          {consultantId
            ? 'Заявка оформлена успешно. Наш юрист свяжется с вами в день обращения'
            : discount
            ? ''
            : 'Будем рады вашему обращению'}
        </Text>
      </TextSection>

      <ApplicationNumber>
        <Text variant="h8">Номер заявки: </Text>
        <Text variant="h6">{applicationNumber}</Text>
      </ApplicationNumber>

      {!discountActivated && (
        <OutlinedButton onClick={returnToForm} width={256}>
          {'Оформить другу'}
        </OutlinedButton>
      )}

      {!discountActivated && gameCount < +process.env.REACT_APP_MAX_GAME_COUNTS! && (
        <DiscountCard handleClick={handlerGetDiscount} />
      )}
    </Wrapper>
  )
}
