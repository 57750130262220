import React from 'react'

import { ErrorType } from 'http/index'

import axios, { AxiosError } from 'axios'
import RequestsService from 'services/main.services'

export type UseGameSessionReturn = ReturnType<typeof useGameSession>

export const useGameSession = () => {
  const [modalVisible, setModalVisible] = React.useState<boolean>(true)
  const [quizRequestError, setQuizRequestError] = React.useState<ErrorType | undefined>(undefined)
  const userId = localStorage.getItem('id') ?? ''

  let bodyParameters = {}

  if (userId.length) {
    bodyParameters = { id: userId, eventId: localStorage.getItem('eventId') }
  } else {
    bodyParameters = { eventId: localStorage.getItem('eventId') }
  }
  React.useEffect(() => {
    const gameCount: number = +(localStorage.getItem('gameCount') ?? 0)

    if (gameCount === 1 && !!localStorage.getItem('quizPassedFirstTime') && localStorage.getItem('quizPassed') === '') {
      setQuizRequestError(undefined)
      RequestsService.updateGameSession(userId)
        .then((response) => {
          localStorage.setItem('gameCount', response.data.gameCount.toString())
          localStorage.removeItem('quizPassedFirstTime')
          setModalVisible(false)
        })
        .catch((err: Error | AxiosError) => {
          if (axios.isAxiosError(err)) {
            setQuizRequestError(err.response?.data)
          }
        })
    } else if (gameCount === 0 || null) {
      setQuizRequestError(undefined)
      RequestsService.startGameSession(bodyParameters)
        .then((response) => {
          window.scrollTo(0, 0)

          localStorage.setItem('gameCount', response.data.gameCount.toString())
          localStorage.setItem('id', response.data.id)

          setModalVisible(false)
        })
        .catch((err: Error | AxiosError) => {
          if (axios.isAxiosError(err)) setQuizRequestError(err.response?.data)
        })
    } else {
      setModalVisible(false)
    }
  }, [])

  return { modalVisible, quizRequestError }
}
