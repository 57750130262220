import React from 'react'

import { ConfirmationCircle, OutlinedButton, BlueButton, Text } from 'ui/components'
import { styled, theme } from 'ui/styles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.white};
  align-items: center;
  width: 100%;
  padding: 32px 16px;
  gap: 26px;

  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
  border-radius: 16px;

  animation: onShow ${theme.transition.slide}ms ease;

  @keyframes onShow {
    0% {
      opacity: 0;
      transform: translateY(-30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const CircleWrapper = styled.div`
  display: flex;
  width: 180px;
  height: 180px;
  justify-content: center;
  align-items: center;
`
const TextSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 8px;
`

type ErrorPopUpProps = {
  errorMessage: string
  handleBack?: () => void
  handleTryAgain?: () => void
  isOnlyBack?: boolean
  isOnlyTryAgain?: boolean
}

export const ErrorPopUp: React.FC<ErrorPopUpProps> = ({
  errorMessage,
  handleTryAgain,
  handleBack,
  isOnlyBack,
  isOnlyTryAgain
}) => {
  return (
    <Wrapper>
      <CircleWrapper>
        <ConfirmationCircle isSuccessfully={false} />
      </CircleWrapper>
      <TextSection>
        <Text variant="h6" align="center">
          Что-то пошло не так((
        </Text>
        <Text variant="t5" align="center" color={theme.palette.gray}>
          {errorMessage}
        </Text>
      </TextSection>

      {!isOnlyBack && (
        <BlueButton onClick={handleTryAgain} width={256} big>
          {'Попробовать ещё раз'}
        </BlueButton>
      )}
      {!isOnlyTryAgain && (
        <OutlinedButton onClick={handleBack} width={256}>
          {'Вернуться'}
        </OutlinedButton>
      )}
    </Wrapper>
  )
}
