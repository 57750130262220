import React from 'react'

import { styled } from '../styles'
import { Button, ButtonProps } from './button'
import { Text } from 'ui/components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const StyledButton = styled(Button)<Props>`
  display: flex;
  max-width: 280px;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: ${({ big }) => (big ? '26px' : '12px')} 32px;

  background: ${({ inverted, theme, disabled }) =>
    disabled ? theme.palette.blue_pastel : inverted ? theme.palette.deep_blue : theme.palette.white};
  color: ${({ inverted, disabled, theme }) =>
    disabled ? theme.palette.light_gray : inverted ? theme.palette.white : theme.palette.deep_blue};
  border: ${({ inverted, disabled, theme }) =>
    `2px solid ${disabled ? theme.palette.light_gray : inverted ? theme.palette.white : theme.palette.deep_blue}`};

  transition-property: color, background-color;
  transition-duration: ${({ theme }) => theme.transition.hover}ms;
  ${({ width }) => width && `max-width: ${typeof width === 'number' ? `${width}px` : width}`};
  ${({ disabled }) => disabled && 'pointer-events: none'};
  white-space: nowrap;

  @media (hover: hover) {
    &:hover {
      background: ${({ theme, inverted, disabled }) =>
        disabled ? '' : inverted ? theme.palette.white : theme.palette.deep_blue};
      color: ${({ theme, inverted, disabled }) =>
        disabled ? '' : inverted ? theme.palette.deep_blue : theme.palette.white};
    }
  }
`

type Props = {
  width?: string | number
  bigText?: boolean
  big?: boolean
  disabled?: boolean
  inverted?: boolean
} & ButtonProps

export const OutlinedButton: React.FC<Props> = ({ width, bigText, big, disabled, inverted, onClick, children }) => (
  <Wrapper>
    <StyledButton width={width} bigText={bigText} big={big} disabled={disabled} inverted={inverted} onClick={onClick}>
      <Text variant="t4" align="center">
        {children}
      </Text>
    </StyledButton>
  </Wrapper>
)
