import React from 'react'

import { Route, Routes } from 'react-router-dom'

import { Application, Questions, StorageRules, NoEvents, ExportStatistic } from 'app/pages/import'
import { ConfirmationCircle, ErrorPopUp, ModalWindow } from 'ui/components'

import { paths } from 'shared/routing/paths'
import { styled } from 'ui/styles'
import { useFetchEvent } from 'shared/hooks'

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const App: React.FC = () => {
  const eventsData = useFetchEvent()

  const privateOutlet = (children: React.ReactElement<any, any>) => {
    return !eventsData.isEventExist ? <NoEvents /> : children
  }

  return (
    <>
      <Routes>
        <Route path={paths.application} element={privateOutlet(<Application />)} />
        <Route path={paths.questions} element={privateOutlet(<Questions />)} />
        <Route path={paths.storageRules} element={privateOutlet(<StorageRules />)} />
        <Route path={paths.exportStatistic} element={<ExportStatistic />} />
      </Routes>
      <ModalWindow isVisible={eventsData.modalVisible} contentMaxWidth="clamp(0px, 95vw, 500px)">
        <LoadingWrapper>
          {eventsData.eventRequestError ? (
            <ErrorPopUp
              errorMessage={eventsData.eventRequestError}
              handleTryAgain={() => {
                window.location.reload()
              }}
              isOnlyTryAgain
            />
          ) : (
            <ConfirmationCircle isLoading />
          )}
        </LoadingWrapper>
      </ModalWindow>
    </>
  )
}
