import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { styled } from '../styles'

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: auto;

  &::after {
    content: '';
    filter: blur(24px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.64);
    z-index: -1;
  }
`

const Content = styled.div<{ maxWidth: string }>`
  display: flex;
  position: relative;
  align-items: center;
  max-width: ${({ maxWidth }) => maxWidth};
  margin: 0 auto;

  transition: opacity 300ms ease, transform 300ms ease;
  opacity: 0;

  &.open {
    opacity: 1;
  }
`

type Props = {
  isVisible: boolean
  onOverlayClick?: () => void
  contentMaxWidth?: string
}

export const ModalWindow: React.FC<Props> = ({ isVisible, contentMaxWidth = '95%', onOverlayClick, children }) => {
  const portal = document.getElementById('modal')
  const root = document.getElementById('root')
  const body = document.body

  const [isOpening, setIsOpening] = useState(isVisible)

  useEffect(() => {
    setIsOpening(isVisible)
  }, [isVisible])

  return portal && isVisible ? (
    <>
      {root?.classList.add('blur')}
      {body.classList.add('overflow-hidden')}
      {ReactDOM.createPortal(
        <Wrapper onClick={onOverlayClick}>
          <Content
            maxWidth={contentMaxWidth}
            className={isOpening ? 'open' : ''}
            onClick={(ev) => {
              ev.stopPropagation()
            }}
          >
            {children}
          </Content>
        </Wrapper>,
        portal
      )}
    </>
  ) : (
    <>
      {root?.classList.remove('blur')}
      {body.classList.remove('overflow-hidden')}
    </>
  )
}
