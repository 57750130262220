import React from 'react'

import { useNavigate } from 'react-router-dom'
import { QuizReturn } from 'shared/hooks/useQuestions'

import { ConfirmationCircle, ModalWindow, OutlinedButton, BlueButton, Text, ErrorPopUp } from 'ui/components'
import { styled, theme } from 'ui/styles'
import { paths } from 'shared/routing/paths'
import { useApplicationSubmit, useUpdateDiscount } from 'shared/hooks'

const Wrapper = styled.div`
  display: flex;
  max-width: 520px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 16px 16px 40px;

  background: ${theme.palette.white};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
`

const CircleWrapper = styled.div`
  display: flex;
  width: 180px;
  height: 180px;
  justify-content: center;
  align-items: center;
`

const TextSection = styled.div`
  display: flex;
  max-width: 312px;
  width: 100%;
  align-items: center;
  flex-direction: column;

  gap: 8px;
`

const ApplicationNumber = styled.div`
  display: flex;
  max-width: 312px;
  width: 100%;
  flex-direction: column;
`

const StyledDiscount = styled.div`
  display: flex;
  max-width: 312px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
`

export const DiscountStatus: React.FC<QuizReturn> = ({ handlerTryAgain, discount }) => {
  const navigate = useNavigate()
  const applicationNumber = localStorage.getItem('applicationNumber') ?? ''
  const discountActivated: boolean = !!(localStorage.getItem('discountActivated') ?? '')

  const discountData = useUpdateDiscount(discount)

  const gameCount = localStorage.getItem('gameCount') ?? 0
  const maxGameCounts = +(process.env.REACT_APP_MAX_GAME_COUNTS ?? 2)

  const { returnToForm } = useApplicationSubmit()

  const handleGoToApplication = () => {
    navigate(paths.application)
    if (!localStorage.getItem('applicationPassed')) {
      localStorage.setItem('discountActivated', '1')
    }
    returnToForm()
  }

  return (
    <Wrapper>
      <CircleWrapper>
        <ConfirmationCircle isSuccessfully={!discountData.discountRequestError}></ConfirmationCircle>
      </CircleWrapper>
      <TextSection>
        {!discountData.isDiscountDowngrade && (
          <Text variant="h6" align="center">
            {!discountData.discountRequestError ? 'ПОЗДРАВЛЯЕМ' : 'Что то пошло не так(('}
          </Text>
        )}
        <Text variant="t5" color={theme.palette.gray} align="center">
          {!discountData.discountRequestError
            ? discountData.isDiscountDowngrade
              ? 'К сожалению, вы не смогли улучшить результат'
              : discountData.calculatedDiscount!.message
            : 'Возможно какая-то техническая ошибка. Попробуйте сыграть заново.'}
        </Text>
      </TextSection>
      {!discountData.discountRequestError && (
        <ApplicationNumber>
          <StyledDiscount>
            <Text variant="h8">Ваша скидка: </Text>
            <Text variant="h4">{localStorage.getItem('discount') ?? discountData.calculatedDiscount!.discount}%</Text>
          </StyledDiscount>
          {applicationNumber && (
            <Text variant="t6" color={theme.palette.middle_gray}>
              {`Номер заявки: ${applicationNumber}`}
            </Text>
          )}
        </ApplicationNumber>
      )}
      {!discountActivated && (
        <ButtonWrapper>
          {gameCount < maxGameCounts && (
            <BlueButton onClick={handlerTryAgain} big>
              Попробовать еще раз
            </BlueButton>
          )}
          <OutlinedButton onClick={handleGoToApplication}>
            {!!localStorage.getItem('applicationPassed') ? 'Оформить другу' : 'Активировать скидку'}
          </OutlinedButton>
        </ButtonWrapper>
      )}
      <ModalWindow isVisible={discountData.modalVisible} contentMaxWidth="clamp(0px, 95vw, 500px)">
        {discountData.discountRequestError?.code ? (
          <ErrorPopUp
            errorMessage={discountData.discountRequestError.message}
            handleTryAgain={handlerTryAgain}
            isOnlyTryAgain
          />
        ) : (
          <ConfirmationCircle isSuccessfully={true} isLoading />
        )}
      </ModalWindow>
    </Wrapper>
  )
}
