import http from '../http-common'

type RequestModel = {
  id: string
  number: string
  firstName: string
  secondName: string
  organization: string
  phone: string
  email: string
  eventId: number
  consultantId: string
  discount: number
  gameCount: number
  applicationIsExist: boolean
}

type UpdateRequestModel = {
  gameCount: number
  id: string
  number: number
  eventId: number
}

class RequestsService {
  getConsultants<TConsultants>(id: string) {
    return http.get<TConsultants>(`/api/main/v1/events/${id}/consultants`)
  }

  sendApplication(body: any, id: string) {
    if (localStorage.getItem('id')) {
      body.id = localStorage.getItem('id')
    }
    if (localStorage.getItem('discount')) {
      body.discount = localStorage.getItem('discount')
    }
    body.eventId = id
    return http.post<RequestModel>('/api/main/v1/applications', body)
  }

  startGameSession(bodyParameters: object) {
    return http.post<UpdateRequestModel>('/api/main/v1/gameSessions', bodyParameters)
  }

  updateGameSession(id: string) {
    return http.put<UpdateRequestModel>(`/api/main/v1/gameSessions/${id}`)
  }
}
export default new RequestsService()
