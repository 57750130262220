import { validate } from 'app/lib/validation'
import React from 'react'

import { InputReturn, useInput } from 'shared/hooks/useInput'

type TRow = {
  label: string
  input?: InputReturn
}

type TRows = Record<string, TRow>

const TEXTAREA_ROWS: TRows = {
  firstName: {
    label: 'Имя'
  },
  secondName: {
    label: 'Фамилия'
  },
  organization: {
    label: 'Компания'
  },
  phone: {
    label: 'Телефон'
  },
  email: {
    label: 'E-mail'
  }
}

export type TConsultants = { consultants: [TConsultant] }
export type TConsultant = { id: number; firstName: string; lastName: string; position: string }

export type ApplicationFormReturn = ReturnType<typeof useApplicationForm>

export const useApplicationForm = () => {
  const [consultantCallbackChecked, setConsultantCallbackChecked] = React.useState<boolean>(
    !localStorage.getItem('discount')
  )
  const [personalDataChecked, setPersonalDataChecked] = React.useState<boolean>(false)

  const [dropDownData, setDropDownData] = React.useState<undefined | TConsultants>(undefined)
  const [dropDownValue, setDropDownValue] = React.useState<undefined | TConsultant>(undefined)

  const onConsultantCallbackChange = (value: boolean) => {
    setConsultantCallbackChecked(() => {
      localStorage.setItem('consultantCallback', `${value}`)
      return value
    })
  }

  const onPersonalDataChange = (value: boolean) => {
    setPersonalDataChecked(value)
  }

  const onDropDownChange = (value: TConsultant) => {
    setDropDownValue(value)
  }

  const TEXTAREA_KEYS = Object.keys(TEXTAREA_ROWS)

  TEXTAREA_ROWS.firstName.input = useInput({
    inputType: 'ru-en',
    initialValue: TEXTAREA_ROWS.firstName.input?.value
  })
  TEXTAREA_ROWS.secondName.input = useInput({
    inputType: 'ru-en',
    initialValue: TEXTAREA_ROWS.secondName.input?.value
  })
  TEXTAREA_ROWS.organization.input = useInput({
    initialValue: TEXTAREA_ROWS.organization.input?.value
  })
  TEXTAREA_ROWS.phone.input = useInput({
    inputType: 'num',
    initialValue: TEXTAREA_ROWS.phone.input?.value,
    validate: (val) => validate(val, 'phone')
  })
  TEXTAREA_ROWS.email.input = useInput({
    inputType: '!ru',
    initialValue: TEXTAREA_ROWS.email.input?.value,
    validate: (val) => validate(val, 'email')
  })

  const isAllValid =
    TEXTAREA_KEYS.every(
      (rowKey) => TEXTAREA_ROWS[rowKey].input?.isValid && TEXTAREA_ROWS[rowKey].input?.value.length
    ) &&
    personalDataChecked &&
    !!dropDownValue?.id

  const handleBlur = () => TEXTAREA_KEYS.forEach((rowKey) => TEXTAREA_ROWS[rowKey].input?.onBlur())

  const getValues = () => {
    const values = TEXTAREA_KEYS.reduce<Record<string, string | number | undefined | null>>((res, rowKey) => {
      res[rowKey] = TEXTAREA_ROWS[rowKey].input!.value
      return res
    }, {})

    if (consultantCallbackChecked) {
      values.consultantId = dropDownValue?.id
    }

    return values
  }

  const clearValues = () => {
    TEXTAREA_KEYS.forEach((element) => {
      TEXTAREA_ROWS[element].input?.clearValue(true)
      TEXTAREA_ROWS[element].input?.setClearButton(false)
    })
    setPersonalDataChecked(false)
    setConsultantCallbackChecked(true)
  }

  const blurFields = (data: string) => {
    if ('email' === data) {
      TEXTAREA_ROWS.email.input?.blurField(false)
    }
    if ('phone' === data) {
      TEXTAREA_ROWS.phone.input?.blurField(false)
    }
  }

  return {
    textAreas: TEXTAREA_ROWS,
    textAreasKeys: TEXTAREA_KEYS,
    personalDataChecked,
    onPersonalDataChange,
    consultantCallbackChecked,
    onConsultantCallbackChange,
    dropDownData,
    dropDownValue,
    setDropDownData,
    onDropDownChange,
    blurFields,
    isAllValid,
    clearValues,
    handleBlur,
    getValues
  }
}
