import { Button, ButtonProps } from 'ui/components'
import { getStyles, styled } from 'ui/styles'

type Props = {
  width?: string | number
  disabled?: boolean
} & ButtonProps

export const FooterButton = styled(Button)<Props>`
  ${getStyles('t4')}

  display: flex;
  width: 100%;
  min-height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: 6px 4px;
  background: ${({ theme, disabled }) => (disabled ? theme.palette.dark_gray : theme.palette.deep_blue)};
  color: ${({ theme }) => theme.palette.white};
  border: 2px solid ${({ disabled, theme }) => (disabled ? theme.palette.dark_gray : theme.palette.white)};

  transition-property: color, background-color;
  transition-duration: ${({ theme }) => theme.transition.hover}ms;
  ${({ width }) => width && `width: ${width}px`};
  ${({ disabled }) => disabled && 'pointer-events: none'};

  &:hover {
    background: ${({ theme }) => theme.palette.white};
    color: ${({ theme }) => theme.palette.deep_blue};
  }
`
