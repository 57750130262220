import React from 'react'

import { Page } from 'app/templates/page'
import { useQuestions } from 'shared/hooks'

import { QuizForm } from 'features/quiz-form'
import { styled, theme } from 'ui/styles'
import { Text } from 'ui/components'
import { ReactComponent as HelicopterSvg } from 'ui/images/helicopter_questions.svg'
import { DiscountStatus } from 'features/quiz-form/organisms'

const ContentWrapper = styled.div`
  display: flex;
  gap: 36px;
  padding: 20px 20px 36px;
  flex-direction: column;
  align-items: center;
`

const HelicopterIcon = styled(HelicopterSvg)`
  display: flex;
  max-width: 222px;
  width: 100%;
  height: fit-content;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`

export const Questions: React.FC = () => {
  const questions = useQuestions()

  const discountActivated: boolean =
    questions.quizPassed ||
    (!!localStorage.getItem('answers') && !!localStorage.getItem('quizPassedFirstTime')) ||
    !!localStorage.getItem('discountActivated') ||
    (+(localStorage.getItem('gameCount') ?? 0) >= 2 &&
      (localStorage.getItem('quizPassed') === null || localStorage.getItem('quizPassed') === '1'))

  return (
    <Page>
      <ContentWrapper>
        <HelicopterIcon />
        <TextWrapper>
          <Text variant="h7" color={theme.palette.deep_blue} align="center">
            {'Симулятор налоговой проверки'}
          </Text>
          <Text variant="h6" color={theme.palette.deep_blue} align="center">
            {'«Откройте, выездная налоговая проверка!»'}
          </Text>
        </TextWrapper>
        {discountActivated ? <DiscountStatus {...questions} /> : <QuizForm {...questions} />}
      </ContentWrapper>
    </Page>
  )
}
