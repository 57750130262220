import React from 'react'
import { useNavigate } from 'react-router-dom'

import { paths } from 'shared/routing/paths'
import { QuizReturn } from 'shared/hooks/useQuestions'

import { styled, theme } from 'ui/styles'
import { ConfirmationCircle, ErrorPopUp, ModalWindow, Text } from 'ui/components'
import { NextButton, PreviousButton, AnswersBar } from '../molecules'

import { useGameSession } from 'shared/hooks'

const Wrapper = styled.div`
  display: flex;
  max-width: 520px;
  width: 100%;
  gap: 8px;
  flex-direction: column;
  padding: 16px 16px 40px;

  background: ${theme.palette.white};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 16px;

  transition: all ${theme.transition.slide}ms ease;

  animation: slideIn ${theme.transition.slide}ms ease;

  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(-30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const Steps = styled.pre`
  display: flex;
  justify-content: right;
  width: 100%;
  flex-direction: row;
`

const FlexText = styled(Text)`
  display: flex;
  min-width: 150px;
  width: 100%;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
`

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const QuizForm: React.FC<QuizReturn> = ({
  questions,
  handlerAnswerClick,
  handleNext,
  handlePrev,
  curStep,
  answers,
  curQuestion
}) => {
  const navigate = useNavigate()
  const gameSessions = useGameSession()

  return (
    <Wrapper>
      <Steps>
        <Text variant="t1" color={theme.palette.gray}>
          Шаг:
        </Text>
        <Text variant="t1" color={theme.palette.link}>
          {`   ${curStep + 1} `}
        </Text>
        <Text variant="t1" color={theme.palette.gray}>
          из {questions.length}
        </Text>
      </Steps>
      <FlexText variant="t4">{curQuestion}</FlexText>
      <AnswersBar
        data={questions[curStep].responses}
        correctAnswer={questions[curStep].answer}
        answers={answers}
        curStep={curStep}
        handlerAnswerClick={handlerAnswerClick}
      />
      <ButtonWrapper>
        {curStep !== 0 && <PreviousButton onClick={handlePrev} />}
        <NextButton
          onClick={handleNext}
          isLastQuestion={curStep + 1 === questions.length}
          isDisabled={!answers[curStep]}
        />
      </ButtonWrapper>

      <ModalWindow isVisible={gameSessions.modalVisible} contentMaxWidth="clamp(0px, 95vw, 500px)">
        <LoadingWrapper>
          {gameSessions.quizRequestError?.code ? (
            <ErrorPopUp
              errorMessage={gameSessions.quizRequestError?.message}
              handleBack={() => {
                navigate(paths.application, { replace: true })
              }}
              isOnlyBack
            />
          ) : (
            <ConfirmationCircle isLoading />
          )}
        </LoadingWrapper>
      </ModalWindow>
    </Wrapper>
  )
}
