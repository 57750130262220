import React from 'react'
import { StyledComponent } from 'styled-components'

import { styled, TypographyVariants, getStyles, Theme } from 'ui/styles'

type Props = {
  variant: TypographyVariants
  inline?: boolean
  color?: string
  align?: 'center' | 'right' | 'left'
  divRef?: () => React.RefObject<HTMLDivElement>
}

type StyledFunc = (props: Props & { children?: React.ReactNode }) => JSX.Element
type TextT = StyledComponent<StyledFunc, Theme, Props>

export const Text: TextT = styled<StyledFunc>(({ variant, inline, color, align, children, divRef, ...rest }) => {
  if (inline) return <span {...rest}>{children}</span>
  return (
    <div {...rest} ref={divRef && divRef()}>
      {children}
    </div>
  )
})<Props>`
  ${({ variant }) => getStyles(variant)}
  ${({ color }) => color && `color: ${color};`}
  ${({ align }) => align && `text-align: ${align};`}
`
