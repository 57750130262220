import React from 'react'

import { Button, Text } from 'ui/components'
import { styled, theme } from 'ui/styles'

import { ReactComponent as ArrowRightSvg } from 'ui/icons/arrow-right.svg'

const Next = styled(Button)<{ isLast: boolean; disabled: boolean }>`
  display: flex;
  max-width: ${({ isLast }) => (isLast ? '129' : '114')}px;
  width: 100%;
  justify-content: space-between;

  padding: 12px 16px 12px 20px;

  background: ${({ disabled }) => (disabled ? `${theme.palette.light_gray}` : `${theme.palette.blue}`)};
  color: ${theme.palette.white};
  border-radius: 50px;

  transition: background ${theme.transition.hover}ms ease;

  @media (hover: hover) {
    &:hover {
      background: ${({ disabled }) => (disabled ? null : `${theme.palette.link}`)};
    }
  }
`

const ArrowRightIcon = styled(ArrowRightSvg)`
  width: 24px;
  height: 24px;
`

type NextButtonProps = {
  isLastQuestion: boolean
  isDisabled: boolean
  onClick: () => void
}

export const NextButton: React.FC<NextButtonProps> = ({ onClick, isLastQuestion, isDisabled }) => {
  return (
    <Next onClick={onClick} isLast={isLastQuestion} disabled={isDisabled}>
      <Text variant="t4">{isLastQuestion ? 'Завершить' : 'Далее'}</Text>
      {!isLastQuestion && <ArrowRightIcon />}
    </Next>
  )
}
