export const theme = {
  palette: {
    deep_blue: '#141F3E',
    white: '#FFFFFF',
    yellow: '#F8D03E',
    blue_pastel: '#EDF0F4',
    link: '#0043FF',
    gray: '#666666',
    middle_gray: '#9D9D9D',
    light_gray: '#CCCCCC',
    dark_gray: '#888888',
    light_blue: '#8AA9FF',
    blue: '#5280FF',
    red: '#FD4141',
    orange: '#FF5722',
    green: '#4CD964'
  },
  fontFamily: {
    PTRootUI: 'PT-Root-UI'
  },
  transition: {
    hover: 300,
    slide: 800
  },
  typography: {
    h1: {
      fontSize: '96px',
      lineHeight: '128px',
      fontWeight: 'bold'
    },
    h2: {
      fontSize: '72px',
      lineHeight: '88px',
      fontWeight: 'normal'
    },
    h3: {
      fontSize: '52px',
      lineHeight: '60px',
      fontWeight: 'bold'
    },
    h4: {
      fontSize: '36px',
      lineHeight: '48px',
      fontWeight: 'bold'
    },
    h5: {
      fontSize: '36px',
      lineHeight: '48px',
      fontWeight: 'normal'
    },
    h6: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 'bold'
    },
    h7: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 'normal'
    },
    h8: {
      fontSize: '22px',
      lineHeight: '26px',
      fontWeight: '300'
    },
    h9: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: '300'
    },
    t1: {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 'normal'
    },
    t2: {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 'bold'
    },
    t3: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'normal'
    },
    t4: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'bold'
    },
    t5: {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 'normal'
    },
    t6: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 'normal'
    },
    t7: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 'normal'
    },
    t8: {
      fontSize: '10px',
      lineHeight: '12px',
      fontWeight: 'normal'
    },
    t_22_22: {
      fontSize: '18px',
      lineHeight: '22px',
      fontWeight: 'bold'
    }
  }
}

export type Theme = typeof theme
