import React from 'react'

import { breakpoints } from 'shared/dimensions'

import { Page } from 'app/templates/page'
import { OutlinedButton, Text } from 'ui/components'
import { styled } from 'ui/styles'

import { baseUrl } from 'http/index'
import axios from 'axios'

import { ReactComponent as PatternSvg } from 'ui/images/patterns/pattern_export.svg'

const Pattern = styled(PatternSvg)`
  top: 30%;
  width: 100%;
  left: 0px;
  right: 0px;
  position: absolute;
`

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  max-width: clamp(0px, 95vw, 930px);
  width: 100%;

  @media (max-width: ${breakpoints.tablet}px) {
    padding: 32px 16px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: 100%;
  justify-content: center;

  gap: 32px;
  padding: 40px 26px;

  background: #ffffff;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
  border-radius: 24px;

  @media (max-width: ${breakpoints.tablet}px) {
    padding: 40px 26px;
    gap: 40px;
  }
`
const StyledText = styled(Text)`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: ${breakpoints.tablet}px) {
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
  }
`

const TitleText = styled(Text)`
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoints.tablet}px) {
    font-size: 16px;
    line-height: 24px;
    flex-direction: column;
    height: auto;
  }
`

const ExportWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  gap: 32px;

  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
  }
`
const Export = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
  align-items: center;

  @media (max-width: ${breakpoints.tablet}px) {
    gap: 16px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  height: 80px;
  width: 100%;

  @media (max-width: ${breakpoints.tablet}px) {
    height: 48px;
  }
`

export const ExportStatistic: React.FC = () => {
  const currentDate: string = new Date().toISOString().split('T')[0]

  const handleExportFromLastEvent = () => {
    axios
      .request({
        url: baseUrl + `/api/main/v1/applications/file?date=${currentDate}`,
        method: 'GET',
        responseType: 'blob'
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'last_event.csv')
        document.body.appendChild(link)
        link.click()
      })
  }

  const handleExportAll = () => {
    axios
      .request({
        url: baseUrl + '/api/main/v1/applications/file',
        method: 'GET',
        responseType: 'blob'
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'all_events.csv')
        document.body.appendChild(link)
        link.click()
      })
  }
  return (
    <Page>
      <ContentWrapper>
        <Pattern />
        <Content>
          <StyledText variant="h2" align="center">
            Выгрузить
            <br />
            список заявок
          </StyledText>
          <ExportWrapper>
            <Export>
              <TitleText variant="h7" align="center">
                Выгрузить заявки
                <br />с последнего мероприятия
              </TitleText>
              <ButtonWrapper>
                <OutlinedButton onClick={handleExportFromLastEvent}>Скачать</OutlinedButton>
              </ButtonWrapper>
            </Export>
            <Export>
              <TitleText variant="h7" align="center">
                Выгрузить все заявки
              </TitleText>
              <ButtonWrapper>
                <OutlinedButton onClick={handleExportAll}>Скачать</OutlinedButton>
              </ButtonWrapper>
            </Export>
          </ExportWrapper>
        </Content>
      </ContentWrapper>
    </Page>
  )
}
