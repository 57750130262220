import React from 'react'

import axios, { AxiosError } from 'axios'
import { ErrorType } from 'http/index'
import { useEvent } from './useDiscount'
import { ApplicationFormReturn, TConsultants } from './useApplicationForm'

import RequestsService from 'services/main.services'
import { paths } from 'shared/routing'
import { useNavigate } from 'react-router-dom'

export type TOption = { consultantId: number; name: string; post: string }

export type FormSubmitReturn = ReturnType<typeof useApplicationSubmit>

export const useApplicationSubmit = (applicationData?: ApplicationFormReturn) => {
  const [applicationRequestError, setApplicationRequestError] = React.useState<ErrorType | undefined>(undefined)
  const [applicationPassed, setApplicationPassed] = React.useState<boolean>(!!localStorage.getItem('applicationPassed'))

  const [modalVisible, setModalVisible] = React.useState<boolean>(false)

  const { eventId } = useEvent()

  const navigate = useNavigate()

  React.useEffect(() => {
    setModalVisible(true)

    RequestsService.getConsultants<TConsultants>(eventId)
      .then((response) => {
        applicationData!.setDropDownData(response.data)
        applicationData!.onDropDownChange(response.data.consultants[0])
        setModalVisible(false)
      })
      .catch((err: ErrorType) => {
        if (axios.isAxiosError(err)) {
          setApplicationRequestError(err.response?.data)
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sendData = () => {
    setApplicationRequestError(undefined)
    if (applicationData!.isAllValid) {
      const applicationValues = applicationData!.getValues()

      setModalVisible(true)

      RequestsService.sendApplication(applicationValues, eventId)
        .then((response) => {
          if (applicationData) {
            applicationData?.clearValues()
          }
          localStorage.setItem('id', response.data.id)
          localStorage.setItem('email', response.data.email)
          localStorage.setItem('phone', response.data.phone)
          localStorage.setItem('applicationNumber', response.data.number)
          localStorage.setItem('gameCount', response.data.gameCount.toString())

          localStorage.setItem('applicationPassed', '1')

          if (response.data.discount) {
            localStorage.setItem('discount', response.data.discount.toString())
          }

          if (response.data.gameCount === 1) {
            localStorage.setItem('quizPassedFirstTime', '1')
            localStorage.setItem('quizPassed', '')
          }

          if (response.data.gameCount !== 2 && !!localStorage.getItem('discountActivated')) {
            RequestsService.updateGameSession(localStorage.getItem('id') ?? '')
          }

          if (response.data.consultantId) {
            localStorage.setItem('consultantId', response.data.consultantId)
          }

          setApplicationPassed(true)
          setModalVisible(false)
          window.scrollTo(0, 600)
        })
        .catch((err: Error | AxiosError) => {
          if (axios.isAxiosError(err)) {
            const data: ErrorType = err.response?.data
            setApplicationRequestError(data)
          }
        })
    } else {
      applicationData!.handleBlur()
    }
  }

  const returnToForm = () => {
    const id = localStorage.getItem('eventId') ?? eventId
    const discountActivated = localStorage.getItem('discountActivated')
    const discount = localStorage.getItem('discount')
    const userId = localStorage.getItem('id')

    localStorage.clear()

    if (discountActivated) {
      localStorage.setItem('discountActivated', `${discountActivated}`)
      localStorage.setItem('id', `${userId}`)
      localStorage.setItem('discount', `${discount}`)
    }
    localStorage.setItem('eventId', id)

    setApplicationPassed(false)
    setModalVisible(false)
  }

  const handlerGetDiscount = () => {
    navigate(paths.questions)
    localStorage.removeItem('answers')
    localStorage.removeItem('currentQuizIndex')
    localStorage.removeItem('currentQuizQuestion')
    window.scrollTo(0, 0)
  }

  return {
    sendData,
    returnToForm,
    handlerGetDiscount,
    applicationPassed,
    setApplicationPassed,
    applicationRequestError,
    setApplicationRequestError,
    modalVisible,
    setModalVisible
  }
}
