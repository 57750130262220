import React from 'react'

import { styled } from '../styles'

import { ReactComponent as CheckSvg } from 'ui/icons/checkbox.svg'
import { ReactComponent as CheckBlueSvg } from 'ui/icons/checkbox-blue.svg'

const Wrapper = styled.div<{ hasLabel: boolean }>`
  display: flex;
  position: relative;
  height: fit-content;
  width: fit-content;

  align-items: center;
  gap: ${({ hasLabel }) => (hasLabel ? '16' : '0')}px;
  cursor: pointer;
`

const CheckBlueIcon = styled(CheckBlueSvg)`
  position: absolute;
  display: flex;
  fill: none;
  left: 6px;
`

const CheckIcon = styled(CheckSvg)`
  position: absolute;
  display: flex;
  fill: none;
  left: 6px;
  z-index: 2;
`

const Button = styled.div<{ isChecked: boolean }>`
  position: relative;
  height: 22px;
  width: 22px;
  border: 1px solid ${({ theme, isChecked }) => (isChecked ? theme.palette.link : theme.palette.gray)};
  background: ${({ theme, isChecked }) => (isChecked ? theme.palette.link : theme.palette.white)};
  border-radius: 8px;
  flex-shrink: 0;
  z-index: 1;

  transition: all ${({ theme }) => theme.transition.hover}ms;
`

const Label = styled.div``

type RadioProps = {
  initialChecked: boolean
  onChange: (value: boolean) => void
}

export const Checkbox: React.FC<RadioProps> = ({ initialChecked, onChange, children }) => {
  const [isChecked, setIsChecked] = React.useState(initialChecked)

  React.useEffect(() => {
    setIsChecked(initialChecked)
  }, [initialChecked])

  const handleClick = () => {
    setIsChecked((prev) => {
      onChange(!prev)
      return !prev
    })
  }

  return (
    <Wrapper onClick={handleClick} hasLabel={!!children}>
      <Button isChecked={isChecked} />
      {isChecked ? <CheckIcon /> : <CheckBlueIcon />}
      <Label>{children}</Label>
    </Wrapper>
  )
}
