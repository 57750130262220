import React from 'react'

import { styled, theme } from 'ui/styles'
import { ReactComponent as CheckSvg } from 'ui/icons/check.svg'
import { ReactComponent as CloseSvg } from 'ui/icons/close.svg'
import { Text } from 'ui/components'

const Wrapper = styled.div`
  display: flex;
  padding: 32px 0;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
`

const Bar = styled.div<{ isCorrect: boolean; questionIsSelected: boolean; isSelected: boolean }>`
  cursor: pointer;
  display: flex;
  min-height: 48px;
  align-items: flex-start;

  padding: 12px 16px;

  gap: 16px;

  background: ${({ questionIsSelected, isCorrect }) =>
    questionIsSelected || isCorrect ? theme.palette.white : theme.palette.blue_pastel};
  border: 1px solid
    ${({ isCorrect, questionIsSelected }) =>
      questionIsSelected
        ? isCorrect
          ? theme.palette.green
          : theme.palette.red
        : isCorrect
        ? theme.palette.green
        : theme.palette.blue_pastel};
  color: ${theme.palette.deep_blue};
  border-radius: 16px;

  transition: all ${theme.transition.hover}ms ease;

  @media (hover: hover) {
    &:hover {
      background: ${({ isSelected }) => (isSelected ? null : `${theme.palette.white}`)};
      ${({ isSelected, isCorrect }) => (isSelected || isCorrect ? null : `border: 1px solid ${theme.palette.link}`)};
    }
  }
`

const CheckIcon = styled(CheckSvg)`
  display: flex;
  width: 24px;
  height: 24px;

  path {
    fill: ${theme.palette.green};
  }
`

const CloseIcon = styled(CloseSvg)`
  display: flex;
  width: 24px;
  height: 24px;

  path {
    fill: ${theme.palette.red};
  }
`

const Answer = styled(Text)`
  display: flex;
  width: 80%;
`

type TAnswerBarProps = {
  data: string[]
  answers: string[]
  correctAnswer: string
  curStep: number
  handlerAnswerClick: (index: number) => void
}

export const AnswersBar: React.FC<TAnswerBarProps> = ({
  data,
  answers,
  correctAnswer,
  curStep,
  handlerAnswerClick
}) => {
  const myAnswer = answers[curStep]
  return (
    <Wrapper>
      {data.map((response, i) => (
        <Bar
          key={i}
          onClick={() => handlerAnswerClick(i)}
          isCorrect={response === correctAnswer && !!myAnswer}
          questionIsSelected={myAnswer === response}
          isSelected={!!myAnswer}
        >
          {response === correctAnswer && !!myAnswer && <CheckIcon />}
          {myAnswer === response && response !== correctAnswer && <CloseIcon />}
          <Answer variant="t3">{response}</Answer>
        </Bar>
      ))}
    </Wrapper>
  )
}
