import React from 'react'
import { Button } from 'ui/components'
import { styled, theme } from 'ui/styles'

import { ReactComponent as ArrowLeftSvg } from 'ui/icons/arrow-left.svg'

const Previous = styled(Button)`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;

  border-radius: 25px;
  background: ${theme.palette.blue_pastel};

  transition: background ${theme.transition.hover}ms ease;

  @media (hover: hover) {
    &:hover {
      background: ${theme.palette.link};

      path {
        stroke: ${theme.palette.white};
      }
    }
  }
`

const ArrowLeftIcon = styled(ArrowLeftSvg)``

type PreviousButtonProps = {
  onClick: () => void
}

export const PreviousButton: React.FC<PreviousButtonProps> = ({ onClick }) => (
  <Previous onClick={onClick}>
    <ArrowLeftIcon />
  </Previous>
)
