import { styled } from 'ui/styles'

import { ReactComponent as PatternFooter } from 'ui/images/patterns/pattern_footer.svg'
import { ReactComponent as PatternHeader } from 'ui/images/patterns/pattern_header.svg'
import { breakpoints } from 'shared/dimensions'

export const FooterPattern = styled(PatternFooter)`
  display: flex;
  position: absolute;

  height: 534px;
  opacity: 0.01;

  @media (min-width: ${breakpoints.desktop}px) {
    height: auto;
    min-width: 100%;
    width: 100%;
  }
`

export const HeaderPattern = styled(PatternHeader)`
  display: flex;
  position: absolute;
  top: 0px;
  height: 100%;

  opacity: 0.02;
`
