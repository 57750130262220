import React from 'react'

import { FooterPattern, Text } from 'ui/components'
import { styled } from 'ui/styles'
import { FooterButton } from '../molecules'

import { ReactComponent as LogoSvg } from 'ui/icons/logo-full.svg'
import { ReactComponent as YouTubeSvg } from 'ui/icons/contacts/youtube.svg'
import { ReactComponent as FaceBookSvg } from 'ui/icons/contacts/facebook.svg'
import { ReactComponent as TelegramSvg } from 'ui/icons/contacts/telegram.svg'
import { breakpoints } from 'shared/dimensions'
import { paths } from 'shared/routing/paths'

const Wrapper = styled.footer`
  display: flex;
  position: relative;
  height: 534px;
  justify-content: center;

  background: ${({ theme }) => theme.palette.deep_blue};

  @media (min-width: ${breakpoints.tablet}px) {
    height: 329px;
  }
  @media (min-width: ${breakpoints.desktop}px) {
    height: 224px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 24px 32px;
  width: 100%;
  gap: 24px;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 24px 40px 32px;
  }
  @media (min-width: ${breakpoints.desktop}px) {
    padding: 40px 52px 40px;
  }
`

const BrandBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${breakpoints.tablet}px) {
    flex-direction: row;

    align-items: center;
    gap: 24px;
  }
  @media (min-width: ${breakpoints.desktop}px) {
    align-items: center;
    gap: 146px;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  width: 170px;
`

const LogoIcon = styled(LogoSvg)``

const PolicyAndLocation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 24px;

  @media (min-width: ${breakpoints.tablet}px) {
    flex-direction: row-reverse;
    height: 88px;
    gap: 0px;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;

  @media (min-width: ${breakpoints.desktop}px) {
    flex-direction: row-reverse;
    gap: 146px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 8px;
  max-width: 300px;
  width: 100%;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 400px;
  gap: 16px;
`

const Contacts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 180px;
  gap: 16px;
`

const Social = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

const EVALUATION_RESULTS_URL = 'https://taxcompliance.su/working_conditions.pdf'

const SocialLink = styled.a``

const YouTubeLogo = styled(YouTubeSvg)``
const FaceBookLogo = styled(FaceBookSvg)``
const TelegramLogo = styled(TelegramSvg)``

const YOUTUBE_REF = 'https://www.youtube.com/channel/UCytU27ZBr0L42DRgKC28Qbw/'
const FACEBOOK_REF = 'https://www.facebook.com/Taxcompliance.su/'
const TELEGRAM_REF = 'https://t.me/taxcompliance_sec'

export const Footer: React.FC = () => {
  return (
    <Wrapper>
      <FooterPattern />
      <ContentWrapper>
        <BrandBlock>
          <LogoWrapper>
            <LogoIcon />
          </LogoWrapper>
          <Text variant="t3" color="white">
            Tax Compliance - мы можем видеть с высоты своего опыта
          </Text>
        </BrandBlock>
        <InfoWrapper>
          <PolicyAndLocation>
            <ButtonWrapper>
              <FooterButton href={paths.storageRules}>Политика конфиденциальности</FooterButton>
              <FooterButton href={EVALUATION_RESULTS_URL}>Результаты оценки условий труда</FooterButton>
            </ButtonWrapper>
            <TextWrapper>
              <Text variant="t3" color="white">
                Москва, Пресненская Набережная д. 10, стр. 2
              </Text>
              <Text variant="t3" color="white">
                Деловой центр «Москва-Сити»,
                <br />
                башня «IQ-квартал» 12-й этаж,
                <br />
                офис 100
              </Text>
            </TextWrapper>
          </PolicyAndLocation>

          <Contacts>
            <Text variant="t3" color="white">
              +7 495 121 04 44
              <br />
              info@taxcompliance.su
            </Text>
            <Social>
              <SocialLink target="_blank" rel="noopener" href={YOUTUBE_REF}>
                <YouTubeLogo />
              </SocialLink>
              <SocialLink target="_blank" rel="noopener" href={FACEBOOK_REF}>
                <FaceBookLogo />
              </SocialLink>
              <SocialLink target="_blank" rel="noopener" href={TELEGRAM_REF}>
                <TelegramLogo />
              </SocialLink>
            </Social>
          </Contacts>
        </InfoWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}
