import React from 'react'

import { Text } from 'ui/components'
import { styled, theme } from 'ui/styles'

import { ReactComponent as HelicopterSvg } from 'ui/images/helicopter.svg'
import { Page } from 'app/templates/page'

const HelicopterIcon = styled(HelicopterSvg)`
  display: flex;
  max-width: 222px;
  width: 100%;
  height: fit-content;
`

const ContentWrapper = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  padding: 20px 20px 36px;
`

const StyledText = styled(Text)`
  display: flex;
  animation: slideText ${theme.transition.slide}ms ease;

  @keyframes slideText {
    0% {
      opacity: 0;
      transform: translateX(-30px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`
export const NoEvents: React.FC = () => (
  <Page>
    <ContentWrapper>
      <HelicopterIcon />
      <StyledText variant="h6" align="center">
        Сайт будет активен
        <br />
        во время работы мероприятия
      </StyledText>
    </ContentWrapper>
  </Page>
)
