import React from 'react'

import { styled } from 'ui/styles'
import { ReactComponent as LogoIcon } from 'ui/icons/logo.svg'
import { HeaderPattern } from 'ui/components/patterns'
import { breakpoints } from 'shared/dimensions'

const Wrapper = styled.header`
  display: flex;
  position: relative;
  height: 52px;
  width: 100%;
  align-items: center;
  background: ${({ theme }) => theme.palette.deep_blue};
`

const ContentWrapper = styled.div`
  display: flex;

  background: ${({ theme }) => theme.palette.deep_blue};
  padding: 0px 20px;

  @media (min-width: ${breakpoints.mobile}px) {
    padding: 0px 24px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 0px 40px;
  }
`

const StyledLogo = styled(LogoIcon)`
  display: flex;
  height: 32px;
  width: 32px;
`

export const Header: React.FC = () => {
  return (
    <Wrapper>
      <HeaderPattern />
      <ContentWrapper>
        <StyledLogo />
      </ContentWrapper>
    </Wrapper>
  )
}
