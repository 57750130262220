import React from 'react'

import { InputReturn } from 'shared/hooks/useInput'

import { getStyles, styled, theme } from '../styles'
import { Text } from './text'

import { ReactComponent as CleanIcon } from 'ui/icons/clean.svg'

const Wrapper = styled.div`
  display: inline-flex;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
  gap: 6px;
`

const StyledText = styled(Text)<{ isValid: boolean }>`
  color: ${({ isValid }) => (isValid ? theme.palette.deep_blue : theme.palette.red)};
  transition-property: color;
  transition-duration: ${theme.transition.hover}ms;
`

const StyledTextArea = styled.input<{ isValid: boolean }>`
  ${getStyles('t5')}

  overflow: hidden;
  align-self: start;
  align-items: center;
  height: 48px;
  width: 100%;
  max-height: 48px;
  padding: 12px 12px 10px;

  background-color: ${({ isValid }) => (isValid ? theme.palette.blue_pastel : theme.palette.white)};
  border-radius: 8px;
  border: ${({ isValid }) => (isValid ? '1px solid  none' : `2px solid ${theme.palette.red}`)};

  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
  transition-property: all;
  transition-duration: ${theme.transition.hover}ms;

  &:hover {
    border: 1px solid ${theme.palette.gray};
  }

  &:focus {
    border: 1px solid ${theme.palette.deep_blue};
    background-color: ${theme.palette.white};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`

const InputWrapper = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 26px;
  height: 48px;
  width: 48px;
`

const Clear = styled(CleanIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`

type Props = {
  label: string
  input: InputReturn
}

export const TextArea: React.FC<Props> = ({ label, input }) => {
  return (
    <Wrapper>
      <StyledText variant="t5" color={input.isValid ? theme.palette.deep_blue : theme.palette.red} {...input}>
        {label}
      </StyledText>
      <StyledTextArea spellCheck={false} {...input} />
      <InputWrapper>
        {(input.value || input.clearButton) && (
          <Clear
            name="clean"
            onMouseDown={() => {
              input.clearValue()
            }}
          />
        )}
      </InputWrapper>
      {!input.isValid && (
        <StyledText variant="t8" color={input.isValid ? theme.palette.deep_blue : theme.palette.red} {...input}>
          Проверьте правильность ввода
        </StyledText>
      )}
    </Wrapper>
  )
}
