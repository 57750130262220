import React from 'react'
import { styled, theme } from 'ui/styles'

const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 60px;
  z-index: 2;

  /* for safari mobile! */
  height: 251px;
  align-self: center;
  justify-content: center;
  align-items: center;
`

const LoadingCircle = styled.div`
  position: absolute;
  display: flex;
  width: 60px;
  height: 60px;

  background: ${theme.palette.light_blue};
  border-width: 4px;
  border-style: solid;
  border-color: ${theme.palette.white} ${theme.palette.white} ${theme.palette.white} transparent;
  border-radius: 50%;

  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;

  &:before,
  &:after {
    position: absolute;
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: ${theme.palette.white};
    left: 4px;
  }

  &:before {
    top: 9%;
  }

  &:after {
    bottom: 9%;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`

const InnerCircle = styled.div`
  position: absolute;
  display: flex;
  width: 54px;
  height: 54px;

  /* for safari mobile! */
  margin: auto;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  background: ${theme.palette.blue};
  border-radius: 50%;
`

export const CircleIndicator: React.FC = () => {
  return (
    <Wrapper>
      <LoadingCircle />
      <InnerCircle />
    </Wrapper>
  )
}
