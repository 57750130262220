import React from 'react'

import { Footer, Header } from 'features/content'
import styled from 'styled-components'

const Content = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  align-items: center;
  height: 100vh;

  @media (min-width: 600px) {
    max-width: 100%;
  }
`

export const Page: React.FC = ({ children }) => (
  <>
    <Header />
    <Content>{children}</Content>
    <Footer />
  </>
)
