import React from 'react'
import { styled, theme } from 'ui/styles'

import { ReactComponent as Check } from 'ui/icons/42/check.svg'
import { ReactComponent as Sadly } from 'ui/icons/sadly.svg'
import { CircleIndicator } from './circle-indicator'

const Wrapper = styled.div<{ isSuccessfully: boolean }>`
  display: flex;
  transform: translateX(0%) translateY(0%);
  color: #111;
  margin: 0px;
  width: 100px;
  height: 100px;
  max-width: 180px;
  max-height: 180px;
  justify-content: center;
  align-items: center;

  @keyframes pulse {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    60% {
      opacity: 0.35;
    }
    100% {
      opacity: 0;
    }
  }

  &:before {
    content: '';
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    min-width: 180px;
    min-height: 180px;
    box-sizing: border-box;
    border-radius: 50%;
    background: ${theme.palette.link};
    opacity: 0.65;
    animation: pulse 2s cubic-bezier(0.31, 0.68, 0.55, 0.97) infinite;
  }

  &:after {
    content: '';
    position: absolute;
    display: flex;
    width: 50%;
    height: 50%;
    min-width: 112px;
    min-height: 112px;
    border-radius: 100px;
    background: rgba(0, 67, 255, 0.65);
  }
`

const CheckSvg = styled(Check)`
  z-index: 1;
`

const SadlySvg = styled(Sadly)`
  z-index: 1;
`

type Props = {
  isSuccessfully?: boolean
  isLoading?: boolean
}

export const ConfirmationCircle: React.FC<Props> = ({ isSuccessfully, isLoading }) => {
  return (
    <Wrapper isSuccessfully={isSuccessfully || false}>
      {isLoading ? <CircleIndicator /> : isSuccessfully ? <CheckSvg /> : <SadlySvg />}
    </Wrapper>
  )
}
