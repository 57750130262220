import { styled } from 'ui/styles'

import { ReactComponent as SeparatorSvg } from 'ui/images/patterns/separator.svg'

const Wrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 34px;
`

const SeparatorLine = styled(SeparatorSvg)`
  position: absolute;
  top: 0;
  display: flex;
  overflow: hidden;
  height: 34px;
`

export const Separator: React.FC = () => (
  <Wrapper>
    <SeparatorLine />
  </Wrapper>
)
