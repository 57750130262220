import React, { useEffect } from 'react'

const RESERVE_QUESTIONS: TQuestion[] = [
  {
    question: 'Решение пресс-секретаря ФНС – это основание проведения ВНП?',
    responses: [
      'Нет, на основании п.1 ст.89 НК РФ',
      'Да, так как пресс-секретарь – сотрудник ИНФС',
      'Да, были введены изменения в НК РФ'
    ],
    answer: 'Нет, на основании п.1 ст.89 НК РФ'
  },
  {
    question: 'Может ли ВНП длиться 1,5 года?',
    responses: [
      'Может, права инспекторов не ограничены',
      'Не может, срок проверки ограничен годом',
      'Может, так как сроки индивидуальны'
    ],
    answer: 'Не может, срок проверки ограничен годом'
  },
  {
    question: 'Если не все инспекторы, проводящие проверку, включены в состав проверяющей группы...',
    responses: ['Проверку можно будет оспорить', 'Это не повлияет на проведение проверки'],
    answer: 'Проверку можно будет оспорить'
  },
  {
    question: 'Правомерна ли просьба инспекторов задержаться во внерабочее время?',
    responses: ['Правомерна', 'Неправомерна', 'Правомерна, но это уже сверхурочная работа'],
    answer: 'Неправомерна'
  },
  {
    question: 'Обязательны ли для выполнения устные требования налоговой?',
    responses: ['Обязательны любые требования', 'Нет, необходима письменная форма'],
    answer: 'Нет, необходима письменная форма'
  },
  {
    question: 'Возможна ли отсрочка на предоставление документов?',
    responses: ['Да, согласно п. 3 ст. 93 НК РФ', 'Отсрочка не предусмотрена', 'Да, налоговая обязана ее одобрить'],
    answer: 'Да, согласно п. 3 ст. 93 НК РФ'
  },
  {
    question: 'Что делать, если не согласен с результатами ВНП?',
    responses: [
      'Подписать и получить акт, ничего не комментируя',
      'Отказаться от подписания акта',
      'Подписать и получить акт и далее подать возражения на него'
    ],
    answer: 'Подписать и получить акт и далее подать возражения на него'
  },
  {
    question: 'Возможны ли 3 ВНП за один отчетный налоговый период?',
    responses: ['Нет, так как есть строгое ограничение', 'Да, согласно п.5 ст.89 НК РФ'],
    answer: 'Да, согласно п.5 ст.89 НК РФ'
  },
  {
    question: 'Может ли инспектор после подписания акта с результатами проверки запрашивать еще документы?',
    responses: [
      'Да, право на запрос документов – бессрочное',
      'Нет, после подписания акта проверяющие лишаются права запрашивать документы'
    ],
    answer: 'Нет, после подписания акта проверяющие лишаются права запрашивать документы'
  },
  {
    question: 'Если была уплачена сумма налога больше необходимой, то...',
    responses: ['Пойдет в зачет следующего налогового периода', 'Она будет возвращена', 'Оба варианта возможны'],
    answer: 'Оба варианта возможны'
  }
]

const QUESTIONS: TQuestion[] = JSON.parse(process.env.REACT_APP_QUESTIONS ?? '[]') ?? RESERVE_QUESTIONS

export type TDiscount = {
  discount: number
  message: string
  pointsNum: number
}

export type TQuestion = {
  question: string
  responses: string[]
  answer: string
}

export type TAnswers = { [value: string]: string }
export type QuizReturn = ReturnType<typeof useQuestions>

export const useQuestions = () => {
  const [curStep, setCurStep] = React.useState(+(localStorage.getItem('currentQuizIndex') ?? 0))

  const discountVariants: TDiscount[] = JSON.parse(process.env.REACT_APP_DISCOUNTS ?? '[]')
  const calculatedDiscount: number = +(localStorage.getItem('discount') ?? 5)

  const finalDiscount: TDiscount =
    discountVariants.find((el) => calculatedDiscount === el.discount) ?? discountVariants[0]

  const [discount, setDiscount] = React.useState<TDiscount>(finalDiscount)

  const [curQuestion, setCurQuestion] = React.useState<string>(
    localStorage.getItem('currentQuizQuestion') ?? QUESTIONS[curStep].question
  )

  const [answers, setAnswers] = React.useState<string[]>(JSON.parse(localStorage.getItem('answers') ?? '[]'))

  const [quizPassed, setQuizPassed] = React.useState<boolean>(
    +(localStorage.getItem('gameCount') ?? 0) >= 2 && localStorage.getItem('quizPassed') === null
  )

  const shuffleResponses = (array: string[]): string[] => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      const temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    return array
  }

  useEffect(() => {
    shuffleResponses(QUESTIONS[curStep].responses)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizPassed])

  const handlerAnswerClick = (index: number) => {
    if (!answers[curStep]) {
      setAnswers((prev) => {
        const data = { ...prev, [curStep]: QUESTIONS[curStep].responses[index] }
        localStorage.setItem('answers', JSON.stringify(data))
        return data
      })
    }
  }

  const handleNext = () => {
    if (answers[curStep]) {
      if (curStep !== QUESTIONS.length - 1) {
        setCurStep((prev) => {
          const data = prev + 1
          localStorage.setItem('currentQuizIndex', data.toString())
          return data
        })
        setCurQuestion(() => {
          const data = QUESTIONS[curStep + 1].question
          localStorage.setItem('currentQuizQuestion', data.toString())
          return data
        })
      } else {
        localStorage.setItem('quizPassedFirstTime', '1')
        localStorage.setItem('quizPassed', '1')
        setQuizPassed(true)

        setDiscount(calculateDiscount())

        setAnswers([])
      }
    }
  }

  const handlePrev = () => {
    setCurStep((prev) => {
      const data = prev - 1
      localStorage.setItem('currentQuizIndex', JSON.stringify(data))
      return data
    })
    setCurQuestion(QUESTIONS[curStep - 1].question)
  }

  const handlerTryAgain = () => {
    setAnswers([])
    setCurStep(0)
    setCurQuestion(QUESTIONS[0].question)

    setQuizPassed(false)

    localStorage.setItem('quizPassed', '')
    localStorage.removeItem('currentQuizIndex')
    localStorage.removeItem('answers')
    localStorage.removeItem('currentQuizQuestion')
  }

  const calculateDiscount = (): TDiscount => {
    let correctAnswers: number = 0
    const discountData: TDiscount[] = JSON.parse(process.env.REACT_APP_DISCOUNTS ?? '[]')

    for (const key in answers) {
      if (QUESTIONS[key].answer === answers[key]) {
        correctAnswers++
      }
    }

    return discountData.find((el) => correctAnswers <= el.pointsNum) ?? discountData[0]
  }

  return {
    questions: QUESTIONS,
    handlerAnswerClick,
    handleNext,
    handlePrev,
    curStep,
    answers,
    curQuestion,
    quizPassed,
    discount,
    handlerTryAgain
  }
}
