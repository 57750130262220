import React from 'react'

import { ApplicationFormReturn } from 'shared/hooks/useApplicationForm'
import { paths } from 'shared/routing/paths'

import { Text, Checkbox, OutlinedButton, Select, TextArea, SpanLink } from 'ui/components'
import { styled, theme } from 'ui/styles'

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  animation: onShow ${theme.transition.slide}ms ease;

  @keyframes onShow {
    0% {
      opacity: 0;
      transform: translateY(-30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

type ApplicationFormProps = {
  sendData: () => void
} & ApplicationFormReturn

export const ApplicationForm: React.FC<ApplicationFormProps> = ({
  sendData,
  textAreas,
  textAreasKeys,
  isAllValid,
  personalDataChecked,
  onPersonalDataChange,
  consultantCallbackChecked,
  onConsultantCallbackChange,
  dropDownData,
  dropDownValue,
  onDropDownChange
}) => {
  const discountIsReceived: string = localStorage.getItem('discount') ?? ''

  return (
    <Wrapper>
      {discountIsReceived ? (
        <Text variant="h6">ЗАПОЛНИТЕ ДАННЫЕ ДЛЯ АКТИВАЦИИ СКИДКИ</Text>
      ) : (
        <Text variant="h6">
          ОФОРМИТЬ ЗАЯВКУ
          <br />
          НА КОНСУЛЬТАЦИЮ
        </Text>
      )}
      <Text variant="t1">Личные данные</Text>
      {textAreasKeys.map((key) => (
        <TextArea key={key} label={textAreas[key].label} input={textAreas[key].input!}></TextArea>
      ))}
      <Checkbox onChange={onConsultantCallbackChange} initialChecked={consultantCallbackChecked}>
        <Text variant="h9">Получить обратный звонок</Text>
      </Checkbox>
      <Select
        label={'Выберите консультанта'}
        options={dropDownData}
        selectedValue={dropDownValue}
        onDropDownChange={onDropDownChange}
        isDisabled={!consultantCallbackChecked}
      />
      <Checkbox onChange={onPersonalDataChange} initialChecked={personalDataChecked}>
        <Text variant="t7">
          Я согласен(на) с
          <SpanLink to={paths.storageRules} target="_blank">
            {' правилами '}
          </SpanLink>
          обработки персональных данных
        </Text>
      </Checkbox>
      <ButtonWrapper>
        <OutlinedButton onClick={sendData} disabled={!isAllValid}>
          Отправить
        </OutlinedButton>
      </ButtonWrapper>
    </Wrapper>
  )
}
