import React from 'react'

import { useApplicationForm, useApplicationSubmit } from 'shared/hooks'

import { ApplicationForm, ApplicationStatus } from 'features/application'
import { Page } from 'app/templates/page'
import { ConfirmationCircle, Separator, Text, ErrorPopUp, ModalWindow } from 'ui/components'
import { styled, theme } from 'ui/styles'

import headerJpg from 'ui/images/header.jpg'

const ContentWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  padding: 20px 20px 36px;
`

const HeaderImage = styled.img`
  display: flex;
  max-width: 600px;
  width: 100%;
  z-index: -1;

  animation: slideImg ${theme.transition.slide}ms ease;

  @keyframes slideImg {
    0% {
      opacity: 0;
      transform: translateX(30px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`

const StyledText = styled(Text)`
  display: flex;
  animation: slideText ${theme.transition.slide}ms ease;

  @keyframes slideText {
    0% {
      opacity: 0;
      transform: translateX(-30px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`
export const Application: React.FC = () => {
  const applicationForm = useApplicationForm()
  const applicationSubmit = useApplicationSubmit(applicationForm)

  return (
    <Page>
      <HeaderImage src={headerJpg} alt="Михаил Бегунов" />
      <ContentWrapper>
        <StyledText variant="t3">
          Команда Tax Compliance консультирует своих клиентов по вопросам применения законодательства о налогах, а также
          представляет их интересы в рамках споров с органами налогового контроля в административном порядке и в
          процессе разбирательств в судах всех инстанций.
        </StyledText>
        <Separator />

        {applicationSubmit.applicationPassed ? (
          <ApplicationStatus
            returnToForm={applicationSubmit.returnToForm}
            handlerGetDiscount={applicationSubmit.handlerGetDiscount}
          />
        ) : (
          <>
            <ApplicationForm sendData={applicationSubmit.sendData} {...applicationForm} />
          </>
        )}

        <ModalWindow isVisible={applicationSubmit.modalVisible} contentMaxWidth="clamp(0px, 95vw, 500px)">
          {applicationSubmit.applicationRequestError?.code ? (
            <ErrorPopUp
              errorMessage={
                applicationSubmit.applicationRequestError.errors![0].message ??
                applicationSubmit.applicationRequestError.message
              }
              handleTryAgain={() => {
                if (applicationSubmit.applicationRequestError?.errors) {
                  applicationForm.blurFields(applicationSubmit.applicationRequestError.errors![0].key)
                }
                applicationSubmit.setModalVisible(false)
              }}
              isOnlyTryAgain
            />
          ) : (
            <ConfirmationCircle isSuccessfully={true} isLoading />
          )}
        </ModalWindow>
      </ContentWrapper>
    </Page>
  )
}
