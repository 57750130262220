import { getStyles, styled } from '../styles'
import { Button, ButtonProps } from './button'

type Props = {
  width?: string | number
  disabled?: boolean
  big?: boolean
} & ButtonProps

export const BlueButton = styled(Button)<Props>`
  ${getStyles('t4')}

  display: flex;
  max-width: 280px;
  width: 100%;
  height: ${({ big }) => (big ? '48' : '40')}px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;

  background: ${({ theme, disabled }) => (disabled ? theme.palette.light_gray : theme.palette.blue)};
  color: ${({ theme }) => theme.palette.white};

  transition-property: color, background-color;
  transition-duration: ${({ theme }) => theme.transition.hover}ms;
  ${({ width }) => width && `width: ${typeof width === 'number' ? `${width}px` : width}`};
  ${({ disabled }) => disabled && 'pointer-events: none'};
  white-space: nowrap;
  @media (hover: hover) {
    &:hover {
      background: ${({ theme }) => theme.palette.link};
      color: ${({ theme }) => theme.palette.white};
    }
  }
`
