import { createGlobalStyle } from 'styled-components'

import { theme } from './theme'

import './reset.css'
import './fonts.css'

export const GlobalStyles = createGlobalStyle`
  body {
    position: relative;
    font-family: ${theme.fontFamily.PTRootUI}, sans-serif;
    color: ${theme.palette.deep_blue};
    overflow-x: hidden;
    overflow-y: auto;

    &.m-show-modal {
      overflow: hidden;
    }
  }

  #root {
    position: relative;
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
    flex-direction: column;
    overflow: hidden;
    transition: filter .3s linear;
  }

  .blur {
    filter: blur(24px);
  }

  .overflow-hidden{
    overflow: hidden;
  }

  b,
  strong {
    font-weight: 600;
  }
`
