import React from 'react'

type InputProps = {
  initialValue?: string
  inputType?: string
  validate?(val: string): boolean
  formatOnBlur?(val: string): string
}

export type PersonalDataReturn = ReturnType<typeof useInput>

export type InputReturn = {
  value: string
  isValid: boolean
  onChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
  onBlur(): boolean
  onFocus(): boolean
  clearButton: boolean
  setClearButton(value: boolean): void
  clearValue(withoutValidation?: boolean): void
  blurField(value: boolean): void
}

const defaultValidate = (val: string) => !!val

export const useInput: (props: InputProps) => InputReturn = ({
  initialValue = '',
  inputType = 'text',
  validate = defaultValidate
}) => {
  const [value, setValue] = React.useState(initialValue)
  const [clearButton, setClearButton] = React.useState(false)
  const [isValid, setIsValid] = React.useState(true)

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (inputType === '!ru') {
      setValue(e.target.value.replace(/[а-яА-Я]/g, '').trim())
    } else if (inputType === 'ru-en') {
      setValue(e.target.value.replace(/[^a-zA-zа-яА-Я]/g, ''))
    } else if (inputType === 'num') {
      setValue(e.target.value.replace(/[^0-9+]/g, ''))
    } else {
      setValue(e.target.value.trim())
    }
    if (!isValid) {
      setIsValid(true)
    }
  }

  const onBlur = () => {
    value.replace(' ', '')
    const isCurrentlyValid = validate(value)

    setValue((prev) => {
      return prev.trim()
    })
    setIsValid(isCurrentlyValid)

    setClearButton(false)

    return isCurrentlyValid
  }

  const onFocus = () => {
    setClearButton(true)
    return false
  }

  const clearValue = (withoutValidation?: boolean) => {
    setValue('')
    if (!withoutValidation) {
      setIsValid(false)
    }
  }

  const blurField = (val: boolean) => {
    setIsValid(val)
  }

  return { value, isValid, onChange, onBlur, onFocus, clearValue, clearButton, setClearButton, blurField }
}
