import React from 'react'

type EventValue = {
  eventId: string
  setEventId: (i: string) => void
}

const EventContext = React.createContext<EventValue | undefined>(undefined)

export const EventProvider: React.FC = ({ children }) => {
  const [eventId, setEventId] = React.useState(localStorage.getItem('eventId') ?? '')

  const setEvent = React.useCallback((i) => setEventId(i), [setEventId])

  return (
    <EventContext.Provider
      value={{
        eventId,
        setEventId: setEvent
      }}
    >
      {children}
    </EventContext.Provider>
  )
}

export const useEvent = () => {
  const context = React.useContext(EventContext)

  if (!context) {
    throw new Error('useEvent must be used within EventProvider')
  }

  return context
}
