import React from 'react'

import { TDiscount } from './useQuestions'
import { baseUrl, ErrorType, headers } from 'http/index'
import axios, { AxiosError } from 'axios'

type RequestModel = {
  gameCount: number
}

export type UseUpdateDiscountReturn = ReturnType<typeof useUpdateDiscount>

export const useUpdateDiscount = (discount: TDiscount | undefined) => {
  const [modalVisible, setModalVisible] = React.useState<boolean>(true)
  const [discountRequestError, setDiscountRequestError] = React.useState<ErrorType | undefined>(undefined)
  const [isDiscountDowngrade, setIsDiscountDowngrade] = React.useState<boolean>(false)

  const calculatedDiscount: TDiscount | undefined = discount

  const userId = localStorage.getItem('id')

  React.useEffect(() => {
    if (calculatedDiscount!.discount >= +(localStorage.getItem('discount') ?? 0)) {
      setIsDiscountDowngrade(false)
      localStorage.setItem('discount', calculatedDiscount!.discount.toString())
    } else {
      setIsDiscountDowngrade(true)
    }

    if (
      localStorage.getItem('applicationPassed') &&
      calculatedDiscount!.discount >= +(localStorage.getItem('discount') ?? 0) &&
      !localStorage.getItem('discountActivated')
    ) {
      const bodyParameters = {
        discount: calculatedDiscount!.discount
      }
      axios
        .put<RequestModel>(baseUrl + `/api/main/v1/applications/${userId}/discount`, bodyParameters, headers)
        .then(() => {
          setModalVisible(false)
        })
        .catch((err: Error | AxiosError) => {
          if (axios.isAxiosError(err)) setDiscountRequestError(err.response?.data)
        })
    } else {
      setModalVisible(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { modalVisible, discountRequestError, calculatedDiscount, isDiscountDowngrade }
}
