type ErrorData = {
  message: string
  key: string
  value: string
}

export const baseUrl = process.env.REACT_APP_API_ENDPOINT

export const headers = {
  headers: { Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` }
}

export type ErrorType = {
  code: string
  message: string
  errors?: ErrorData[]
}

export const BusinessLogicErrors = [
  'AuthorizationError',
  'ValidationError',
  'NotUniqueError',
  'SendDataBitrixError',
  'SendEmailError'
]
